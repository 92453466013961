.mainPageContainer {
    position: relative;
}

.noDisplay {
    display: none!important;
}

.waveBg1 {
    position: absolute;
    right: -59%;
    top: -490px;
    z-index: -1;
}

.waveBg2 {
    position: absolute;
    left: -117%;
    top: -45px;
    z-index: -1;
}

.mainPageTitle {
    margin-top: 50px;
    margin-bottom: 14px;
}

.mainPageTitle h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    color: #32335C;
    line-height: 51px;
}

.mainPageTitle h1 span {
    color: #21CB7A;
    font-weight: bold;
    display: block;
}

.mainPageDescription h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #1D77BA;
    line-height: 24px;
}

.mainPageFormContainer {
    background: #FFFFFF;
    box-shadow: 0px 12px 84px rgba(55, 87, 124, 0.2);
    border-radius: 15px;
    padding: 14px;
    max-width: 418px;
    float: right;
    width: 100%;

    margin-top: 0;
    margin-bottom: 50px;
}

.mainPageFormTitle {
    border-bottom: 1px solid #18A56A;
    padding-bottom: 5px;
    margin-bottom: 13px;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    color: #18A56A;
    text-align: center;
}

.inputBox {
    margin-bottom: 26px;
}

.inputBox.radioInputBox{
    margin-bottom: 0px!important;
}

.dateInputCol .inputBox{
    margin-bottom: 15px;
}

.inputBox label {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #32335C;
    margin-bottom: 5px;
    padding-left: 9px;
}

.getAQuoteButton {
    margin: 0 auto;
}

.radioBlockRow {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 5px;
}

.addDependentsButton {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #91ADC1;
    border-radius: 5px;
    height: 52px;
    width: 52px;
    text-align: center;
    float: right;
    margin-top: 28px;
    background-color: #FFFFFF;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.addDependentsButton:hover {
    border-color: #21CB7A;
    color: #21CB7A;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.mainPageHowItWork img {
    width: 276px;
    margin-top: 33px;
}

.mainPageInfoBoxContainer {
    padding: 0 30px;
    margin: 35px 0 79px 0;
}

.mainPageInfoBox {
    background: #E4F5ED;
    border-radius: 15px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #32335C;
    padding: 20px 0;
}

.mainPageInfoBox span {
    font-weight: bold;
}

.mainPageInfoBox .col-3 {
    -ms-flex: 0 0 88px;
    flex: 0 0 88px;
    max-width: 88px;
    padding: 0;
    align-items: center;
    display: flex;
    justify-content: center;
}

.mainPageInfoBox .col-9 {
    -ms-flex: 0 0 calc(100% - 88px);
    flex: 0 0 calc(100% - 88px);
    max-width: calc(100% - 88px);
    padding: 0 17px 0 0;
}

.mainPageInfoBox .col-3 span {
    color: #04D973;
    font-size: 49px;
    font-weight: normal;
}

.mainPageFormContainer .dependentsContainer .row:first-child {
    margin-top: -15px;
}

.dependentsItemRow .inputBox {
    margin-bottom: 10px;
}
.expatriation .inputBox {
    margin-bottom: 15px;
}

.deleteDependentButton {
    height: 40px;
    color: #FF5353;
    font-size: 24px;
    border-radius: 5px;
    width: 52px;
    background-color: #FFFFFF;
    float: right;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.deleteDependentButton:hover {
    border-color: #FF5353;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.insuranceRow .col-7 {
    padding-right: 10px;
}

.insuranceRow .col-3 {
    padding-left: 10px;
    padding-right: 10px;
}

.handleGetAQuoteButton {
    margin: 0 auto;
    padding: 10px 82px 10px 55px;
    width: 100%;
}

.handleGetAQuoteButton span {
    font-size: 24px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 23px;
}











/* TESTING MODE */

.testingVersionForMainForm .mainPageFormContainer {
    margin-top: -5px;
}

@media screen and (min-width: 992px) and (min-height: 850px) {
    .testingVersionForMainForm .mainPageFormContainer {
        margin-top: 80px;
    }
}

/* TESTING MODE */






/* INPUT SELECT STYLE START */

.insurance-main-select__control {
    height: 52px;
    border: 1px solid #E0E1E5 !important;
    border-radius: 5px !important;
}
.insurance-main-select__control--is-focused {
    border-color: #1BCD78 !important;
    box-shadow: 0px 0px 5px rgba(27, 205, 120, 0.5) !important;
}

.insurance-main-select__control--is-focused .insurance-main-select__indicator {
    color: #32335C !important;
}

.insurance-main-select__value-container {
    padding-left: 19px !important;
}

.insurance-main-select__indicator {
    padding-left: 10px !important;
    padding-right: 20px !important;
    font-size: 7px;
    color: #91ADC1 !important;
}

.insurance-main-select__indicator-separator {
    display: none;
}

.insurance-main-select__placeholder {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #91ADC1 !important;
}

.insurance-main-select__single-value {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #1BCD78 !important;
}

.insurance-main-select__option {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    padding-left: 24px !important;
    color: #91ADC1 !important;
}

.insurance-main-select__option--is-focused {
    background-color: rgba(163, 219, 195, 0.3) !important;
}

.insurance-main-select__option--is-selected {
    background-color: #1BCD78 !important;
    color: #FFFFFF !important;
}

.dependentsItemRow .insurance-main-select__control {
    height: 40px;
}

/* INPUT SELECT STYLE END */



/* DATEPICKER  */



.dateInputCol label,
.dateInputCol .react-datepicker-wrapper{
    width: 100%;
}

.dateInputCol .insurance-main-datepicker {
    box-shadow: 0px 12px 84px 0px rgba(55, 87, 124, 0.20)!important;
    border-radius: 15px!important;
}

.dateInputCol .react-datepicker-wrapper {
    position: relative;
}

.dateInputCol .react-datepicker__triangle{
    display: none;
}

.dateInputCol .react-datepicker__header {
    background-color: #fff!important;
    position: relative;
}

.dateInputCol .react-datepicker__day-names:before{
    width: 90%;
    content: "";
    height: 1px;
    background: #E0E1E5;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.dateInputCol .react-datepicker__current-month{
    color: #1BCD78!important;

    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 7px 0 15px;
}

.dateInputCol .react-datepicker__day-name{
    color: #91ADC1!important;

    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dateInputCol .react-datepicker__day-name,
.dateInputCol .react-datepicker__day,
.dateInputCol .react-datepicker__time-name{
    width: 3rem;
}

.react-datepicker__day:hover:before{
    content: "";
    width: 25px;
    height: 25px;
    z-index: -1;
    background-color: #E0E1E5 !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50px;

    transition: all 0.4s;
}

.dateInputCol .react-datepicker__day:hover{
    background-color: transparent!important;
}

.dateInputCol .react-datepicker__day {
    color: #000;

    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    position: relative;
    z-index: 1;
}

.react-datepicker__navigation {
    position: absolute!important;
    text-indent: unset!important;
    color: #FFFFFF!important;
}

.react-datepicker__navigation--next{
    width: 30px!important;
    height: 31px!important;
    right: 40px!important;
}

.react-datepicker__navigation--next:before{
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDggMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjI0NTUyIDE0LjI0NDFMNyA3Ljk5OTk3TDEuMjQ1NTIgMS43NTU4NiIgc3Ryb2tlPSIjOTFBREMxIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 2;
}

.react-datepicker__navigation--previous{
    width: 30px!important;
    height: 31px!important;
    left: 40px!important;
    margin-top: 3px;
}

.react-datepicker__navigation--previous:before{
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDggMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjI0NTUyIDE0LjI0NDFMNyA3Ljk5OTk3TDEuMjQ1NTIgMS43NTU4NiIgc3Ryb2tlPSIjOTFBREMxIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
    position: absolute;
    left: 0px;
    top: -10px;
    z-index: 2;
    transform: rotate(180deg);
}

.dateInputCol .react-datepicker__header {
    border: none;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.dateInputCol .react-datepicker__week{
    padding: 6px 0;
}

.dateInputCol .react-datepicker-wrapper:after {
    content: "\e900";
    font-family: "icomoon"!important;
    position: absolute;
    right: 15px;
    top: 50%;
    font-size: 22px;
    color: #1BCD78;
    transform: translateY(-50%);
}

.dateInputCol .inputBox input {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #1BCD78;
    padding: 13px 21px;
    background: #FFFFFF;
    border: 1px solid #E0E1E5;
    box-sizing: border-box;
    border-radius: 5px;
    display: block;
    width: 100%;
}

.react-datepicker-popper,
.insurance-main-datepicker {
    width: calc(100% - 15px) !important;
}

.insurance-main-datepicker {
    border-radius: 10px !important;
    border: none !important;
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16) !important;
}

.react-datepicker__month-container {
    width: 100% !important;
    font-family: Poppins !important;
}

.react-datepicker__header {
    background-color: #21CB7A !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
    color: #FFFFFF !important;
}

.react-datepicker__current-month:first-letter {
    text-transform: uppercase;
    font-size: 16px;
}

.react-datepicker__day-name {
    font-size: 16px;
}

.react-datepicker__day-name:first-letter {
    text-transform: uppercase;
}

.react-datepicker__day {
    border-radius: 50% !important;
    color: #32335C !important;
    font-size: 14px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.react-datepicker__day--disabled {
    opacity: 0.3;
}

.dateInputCol .react-datepicker__day--selected {
    background-color: transparent !important;
    font-weight: bold !important;
    color: #FFFFFF !important;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.react-datepicker__day--selected:before{
    content: "";
    width: 25px;
    height: 25px;
    z-index: -1;
    background-color: #21CB7A !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50px;

    transition: all 0.4s;
}

.react-datepicker__day:hover {
    background-color: #E0E1E5 !important;
    color: #32335C !important;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.react-datepicker__day--disabled:hover {
    background-color: transparent !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: transparent !important;
    color: #32335C !important;
}

.react-datepicker__month {
    margin: 15px !important;
}

.react-datepicker__navigation--previous {
    border-right-color: #FFFFFF !important;
}

.react-datepicker__navigation--next {
    border-left-color: #FFFFFF !important;
}

.react-datepicker-popper[data-placement="bottom-start"] .react-datepicker__triangle {
    border-bottom-color: #21CB7A !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: #21CB7A !important;
}

.react-datepicker__triangle {
    left: initial !important;
    right: 18px !important;
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16) !important;
}

.react-datepicker-popper[data-placement="top-start"] .react-datepicker__triangle {
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16) !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
    border-top-color: #FFFFFF !important;
}











@media screen and (min-width: 992px) and (max-height: 850px) {

    .mainPageInfoBoxContainer {
        margin: 25px 0 50px 0;
    }

    .mainPageHeaderStyle .headerContainer {
        padding-top: 35px;
    }

    .mainPageTitle h1 {
        font-size: 30px;
        line-height: 40px;
    }

    .mainPageTitle {
        margin-top: 50px;
    }

    .mainPageHowItWork img {
        width: 249px;
        margin-top: 25px;
    }

    .mainPageDescription h2 {
        font-size: 15px;
        line-height: 23px;
    }

    .mainPageFormContainer {
        margin-top: -27px;
    }

}


@media screen and (min-width: 992px) and (max-height: 780px) {

    .mainPageTitle {
        margin-top: 15px;
    }

    .mainPageFormContainer {
        margin-top: -61px;
    }

}


@media screen and (max-width: 991px) {

    .mainPageFormContainer {
        float: initial;
        margin: 0 auto;
    }

    .waveBg1 {
        right: -131%;
        top: -270px;
    }

    .waveBg2 {
        left: -141%;
        top: -15px;
    }

    .mainPageTitle,
    .mainPageDescription,
    .mainPageHowItWork {
        text-align: center;
    }

    .mainPageTitle {
        margin-top: 0;
    }

    .mainPageMobileHead {
        margin-bottom: 30px;
    }

    .expatLogoBlock {
        text-align: center;
    }

    .mainPageInfoBox {
        max-width: 467px;
        width: 100%;
        margin: 0 auto;
    }

    .mainPageInfoBoxContainer {
        margin-bottom: 25px;
    }

    .scrollToFormButton {
        max-width: 467px;
        width: 100%;
        margin: 0 auto 30px auto;
    }

    .mainPageTitle h1 {
        font-size: 33px;
        line-height: 45px;
    }

    .mainPageHeaderStyle .headerContainer {
        height: auto;
        padding-top: 30px;
        padding-bottom: 20px;
        line-height: 0;
    }

    .mainPageFormContainer {
        padding: 15px 30px 30px 30px;
    }

}


@media screen and (max-width: 767px) {

    .mainPageMobileHead {
        margin-bottom: 15px;
    }

    .mainPageTitle {
        margin-bottom: 0;
    }

    .mainPageHeaderStyle .headerContainer {
        padding-top: 22px;
        padding-bottom: 13px;
    }

    .mainPageTitle h1 {
        font-size: 27px;
        line-height: 40px;
    }

    .expatLogoBlock img {
        width: 90px;
    }

    .mainPageFormTitle {
        font-size: 18px;
        line-height: 27px;
    }

    .mainPageFormContainer {
        padding: 15px;
    }

    .handleGetAQuoteButton {
        width: 100%;
    }

    .mainPageContainer {
        padding: 0 25px;
    }

    .mainPageInfoBoxContainer {
        padding: 0 15px;
    }

    .insurance-main-select__value-container {
        padding-left: 15px !important;
    }

    .insuranceRow .col-7 {
        -ms-flex: 0 0 55%;
        flex: 0 0 55%;
        max-width: 55%;
        padding-right: 5px;
    }

    .insuranceRow .col-3 {
        -ms-flex: 0 0 26%;
        flex: 0 0 26%;
        max-width: 26%;
        padding-left: 5px;
        padding-right: 5px;

    }

    .insuranceRow .col-2 {
        -ms-flex: 0 0 19%;
        flex: 0 0 19%;
        max-width: 19%;
    }

    .insurance-main-select__indicator {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .addDependentsButton {
        font-size: 14px;
        line-height: 16px;
        width: 45px;
    }

    .deleteDependentButton {
        width: 45px;
        font-size: 22px;
    }

    .radioBlockRow label {
        padding-left: 0;
        padding-right: 30px !important;
        text-align: left !important;
    }

    .radioInputBox {
        margin-bottom: 8px;
    }



    .mediumRadioCol {
        padding-left: 10px;
    }

    .radioBlockRow .col-6 {
        padding-left: 10px;
        padding-right: 11px;
        text-align: right;
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
        font-size: 15px;
    }

    .mainPageFormContainer .insurance-main-select__placeholder {
        clear: both;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
    }

    .inputBox {
        margin-bottom: 20px;
    }

    .select_nationality .insurance-main-select__menu {
        min-width: 200px;
    }

    .mainPageFormContainer .dependentsContainer .row:first-child {
        margin-top: -10px;
    }

    .handleGetAQuoteButton {
        padding: 10px 13px 10px 0;
    }

    .handleGetAQuoteButton span {
        right: 13px;
    }




    .waveBg1 {
        /*display: none;*/
        right: -82%;
        top: -25px;
        width: 580px;
    }

    .waveBg2 {
        /*display: none;*/
        left: -74%;
        top: 125px;
        width: 510px;
    }


    .react-datepicker__month{
        margin: 15px 0;
    }

    .dateInputCol .react-datepicker__day-name, .dateInputCol .react-datepicker__day, .dateInputCol .react-datepicker__time-name {
        width: calc(100%/8);
    }

    .react-datepicker__navigation--next {
        right: 20px!important;
    }

    .react-datepicker__navigation--previous{
        left: 20px!important;
    }

}


@media screen and (max-width: 365px) {

    .mainPageTitle h1 {
        font-size: 25px;
        line-height: 35px;
    }

    .mainPageContainer {
        padding: 0 15px;
    }

    .inputBox label {
        font-size: 13px;
        padding-left: 5px;
    }

    .addDependentsButton {
        font-size: 13px;
        line-height: 15px;
        width: 40px;
    }

    .deleteDependentButton {
        width: 40px;
        font-size: 20px;
    }

    .mainPageFormContainer .insurance-main-select__value-container {
        padding-left: 12px !important;
    }

    .mainPageFormContainer .insurance-main-select__indicator {
        padding-left: 5px !important;
    }

    .mainPageFormContainer .insurance-main-select__placeholder {
        font-size: 14px;
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
        font-size: 13px;
    }

    .mediumRadioCol {
        padding-left: 4px;
    }

    .radioBlockRow .col-6 {
        padding-left: 0;
        padding-right: 10px;
    }

    .inputBox {
        margin-bottom: 15px;
    }

    .mainPageHowItWork img {
        width: 80%;
        margin-top: 30px;
    }

    .scrollToFormButton.secondaryButtonStyle {
        padding: 10px 20px;
        font-size: 18px;
    }

    .mainPageInfoBoxContainer {
        margin-top: 30px;
    }

    .mainPageFormContainer .dependentsContainer .row:first-child {
        margin-top: -5px;
    }


    .waveBg1 {
        right: -87%;
        top: 15px;
        width: 500px;
    }

    .waveBg2 {
        left: -90%;
        top: 150px;
        width: 450px;
    }

}

/* text part */

.postContainer {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    line-height: 27px;
    color: #32335C;
    width: 100%;
    max-width: 770px;
    margin-bottom: 100px;
}

.postContainer h1 {
    font-weight: 700;
    font-size: 35px;
    line-height: 50px;
    color: #32335C;
    margin: 35px 0 !important;
}

.postContainer h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    color: #02d973;
    margin: 30px 0 !important;
}

.postContainer h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #738593;
    margin: 25px 0 !important;
}

.postContainer h4 {
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    color: #738593;
    margin: 20px 0 !important;
}

.postContainer ul,
.postContainer ol {
    margin: 20px 0 25px 0;
    padding-left: 30px;
}

.postContainer ul {
    list-style: disc;
}

.postContainer ol {
    list-style-type: decimal;
}

.postContainer ul li,
.postContainer ol li {
    margin-bottom: 10px;
}

.postContainer p {
    margin-bottom: 15px;
}

.postContainer a {
    color: #02D973;
}

.postContainer a:hover {
    text-decoration: none;
}

.postContainer img {
    width: 100%;
    height: auto;
    max-height: 650px;
    object-fit: cover;
    margin: 25px 0 35px 0;
}

@media only screen and (max-width: 1200px) {

    .postContainer {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 80px;
    }

    .postContainer h1 {
        font-size: 32px;
        line-height: 46px;
    }

    .postContainer h2 {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 25px !important;
    }

    .postContainer h3 {
        font-size: 20px;
        line-height: 30px;
    }

    .postContainer h4 {
        font-size: 18px;
        line-height: 28px;
    }

    .postContainer img {
        margin: 20px 0 30px 0;
    }

}


@media only screen and (max-width: 767px) {

    .postContainer {
        margin-bottom: 50px;
    }

    .postContainer h1 {
        font-size: 24px;
        line-height: 32px;
        margin: 25px 0 !important;
    }

    .postContainer h2 {
        font-size: 21px;
        line-height: 31px;
        margin-bottom: 20px !important;
    }

    .postContainer h3 {
        font-size: 18px;
        line-height: 27px;
    }

    .postContainer h4 {
        font-size: 16px;
        line-height: 24px;
    }

    .postContainer ul,
    .postContainer ol {
        margin: 20px 0;
        padding-left: 25px;
    }

    .postContainer ul li,
    .postContainer ol li {
        margin-bottom: 5px;
    }

}


/* Legal  */

span.red{
    color:#E65853!important;
}

.checkboxCol {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.checkboxColDiv p{
    color: #91ADC1;

    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 15px;
}

.checkboxColDiv input{
    width: 20px;
    height: 20px;
}

.legalCol p {
    padding-top: 10px;
    color: #91ADC1;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 8.8px */
}

.legalCol p a {
    color: #0AD675;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-decoration-line: underline;
}

/* Legal  */

/* FORM VALIDATION SHAKE */
.form-validation-error .not-valid-input input,
.form-validation-error .not-valid-input .css-yk16xz-control{
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
}
@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

@media screen and (max-width: 767px){
    .bottomRow .checkboxCol.col-6,
    .bottomRow .checkboxCol.col-5,
    .bottomRow .checkboxCol.col-7 {
        padding-bottom: 15px!important;
    }
    .modal-content .bottomRow {
        flex-direction: column-reverse;
    }
}

/* FORM VALIDATION SHAKE */
