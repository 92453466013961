.summaryCard {
    background: #FFFFFF;
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
    border-radius: 15px;
    margin-bottom: 20px;
}

.summaryCard:first-child {
    /*margin-top: 30px;*/
}

.summaryCard:last-child {
    margin-bottom: 0 !important;
}

.summaryCardTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 50px;
    color: #FFFFFF;
    background: #91ADC1;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 50px;
    text-align: center;
}

.summaryCardLogo {
    text-align: center;
    margin: 10px 0;
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.summaryCardLogo img {
    max-width: 166px;
    max-height: 100px;
}

.insurancePlanCard .summaryCardGuaranteeLevel {
    position: relative;
    text-align: center;
    width: 100%;
}

.insurancePlanCard .summaryCardGuaranteeLevel .planGuaranteeLevelName {
    position: relative;
    display: inline-block;
    left: initial;
}


.summaryCardBody {
    padding: 25px 40px;
}

.insurancePlanCard .summaryCardBody {
    padding-bottom: 0;
}

.travelSummaryRow {
    margin-bottom: 19px;
}

.travelSummaryRow:last-child {
    margin-bottom: 0 !important;
}

.travelSummaryName {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #90ADC0;
    margin-bottom: 10px;
}

.travelSummaryValue {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: #32335C;
}

.summaryCardTypeOfCoverageList {
    padding: 21px 0;
}

.summaryCardTypeOfCoverageList ul {
    column-count: 2;
    grid-column-gap: 10px;
    column-gap: 15px;
}

.summaryCardTypeOfCoverageList ul li {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 26px;
}

.summaryCardAdditionalOptionsBlock {
    padding: 0;
}

.summaryCardAdditionalOptions {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #32335C;
    margin-bottom: 20px;
    text-align: center;
    border: 1px solid #E0E1E5;
    padding: 10px;
    border-radius: 5px;
}

.summaryCardAdditionalOptions span {
    font-weight: bold;
}

.summaryCardTotalPriceTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: #32335C;
    padding-right: 0;
}

.summaryCardTotalPriceValue {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 26px;
    text-align: right;
    color: #1BCD78;
}

.summaryCardTotalPrice {
    padding: 15px 35px;
    background-color: #F7F8FC;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.summaryCardViewButton .viewPlanButton {
    border: 1px solid #21CB7A;
    height: 52px;
    font-size: 21px;
    line-height: 31px;
    text-align: center;
    color: #21CB7A;
    font-weight: bold;
    width: 100%;
}

.userDataContainer {
    background-color: #F6F7FC;
    position: relative;
    padding: 30px 0 60px 50px;
    min-height: 100%;
}

.userDataContainer:after {
    content: '';
    position: absolute;
    background-color: #F6F7FC;
    top: 0;
    right: -199%;
    height: 100%;
    width: 200%;
    z-index: -1;
}

.userDataCard {
    background: #FFFFFF;
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
    border-radius: 15px;
    margin-bottom: 20px;
    padding: 25px 48px;
}

.userDataCard:last-child {
    margin-bottom: 0 !important;
}

.userDataCardTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: #32335C;
    text-align: center;
    margin-bottom: 10px;
}

.userDataCardTitle span {
    color: #21CB7A;
    margin-right: 5px;
    font-size: 24px;
    position: relative;
    top: 3px;
}

.userDataCardSubTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #91ADC1;
    text-align: center;
    margin-bottom: 30px;
}

.userDataCard .inputBox {
    margin-bottom: 20px;
}

.userDataCard .inputBox label {
    margin-bottom: 15px;
    padding-left: 20px;
}

.userDataCard .inputBox label span {
    font-weight: normal;
    color: #91ADC1;
    padding-left: 5px;
}

.userDataCard .inputBox input {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #1BCD78;
    padding: 13px 21px;
    background: #FFFFFF;
    border: 1px solid #E0E1E5;
    box-sizing: border-box;
    border-radius: 5px;
    display: block;
    width: 100%;
}

.userDataCard .inputBox textarea {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #1BCD78;
    padding: 13px 21px;
    background: #FFFFFF;
    border: 1px solid #E0E1E5;
    box-sizing: border-box;
    border-radius: 5px;
    display: block;
    width: 100%;
}

.receiveACall {
    float: right;
}

.userDataCard .react-tel-input input {
    padding-left: 55px;
    height: 52px;
}

.userDataCard .react-tel-input input:hover {
    border-color: #E0E1E5;
}

.userDataCard input:focus,
.userDataCard textarea:focus{
    border-color: #1BCD78 !important;
    box-shadow: 0px 0px 5px rgba(27, 205, 120, 0.5) !important;
}

.userDataCard .react-datepicker__input-container input:hover {
    cursor: pointer;
    box-shadow: 0px 0px 5px rgba(27, 205, 120, 0.5) !important;
}

.userDataCard .react-tel-input .form-control+div:before {
    display: none;
}

.userDataCard .react-datepicker-wrapper {
    position: relative;
    width: 100%;
}

.userDataCard .react-datepicker-wrapper:after {
    content: "\e900";
    font-family: 'icomoon' !important;
    position: absolute;
    right: 15px;
    top: 50%;
    font-size: 22px;
    color: #91ADC1;
    -webkit-transform: translateY(-50%);
    -moz-transform:    translateY(-50%);
    -ms-transform:     translateY(-50%);
    -o-transform:      translateY(-50%);
    transform:         translateY(-50%);
}

.insureesListContainer {
    border-bottom: 1px solid #E0E1E5;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.goToResultPage {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    height: 60px;
    color: #1BCD78;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    line-height: 60px;
    border: 1px solid transparent;
    background-color: transparent;
    margin: 20px 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.goToResultPage span {
    font-size: 24px;
    margin-right: 15px;
    position: relative;
    top: 5px;
}

.goToResultPage:hover {
    border-color: #1BCD78;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.handleReceiveACall {
    float: right;
    padding: 11px 45px 11px 15px;
}

.handleReceiveACall span {
    font-size: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
}

.summaryCard .paymentFrequencyBlock label {
    font-size: 14px;
    line-height: 20px;
}

.summaryCard .paymentFrequencyBlock {
    margin-bottom: 21px;
}

.summaryCard .paymentFrequencyBlock .payment-frequency-option {
    top: 4px;
}

.summaryCard .payment-frequency-option .insurance-main-select__single-value {
    font-size: 16px;
    font-weight: bold;
}

.getPlanInstructionBlock {
    background-color: #21CB7A;
    height: 93px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.getPlanInstructionIcons {
    display: inline-block;
    margin-right: 43px;
}

.getPlanInstructionText {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    display: inline-block;
}

.getPlanInstructionText span {
    font-weight: bold;
}

/* DATEPICKER  */

.react-datepicker-popper,
.insurance-main-datepicker {
    width: calc(100% - 15px) !important;
}

.insurance-main-datepicker {
    border-radius: 10px !important;
    border: none !important;
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16) !important;
}

.react-datepicker__month-container {
    width: 100% !important;
    font-family: Poppins !important;
}

.react-datepicker__header {
    background-color: #21CB7A !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
    color: #FFFFFF !important;
}

.react-datepicker__current-month:first-letter {
    text-transform: uppercase;
    font-size: 16px;
}

.react-datepicker__day-name {
    font-size: 16px;
}

.react-datepicker__day-name:first-letter {
    text-transform: uppercase;
}

.react-datepicker__day {
    border-radius: 50% !important;
    color: #32335C !important;
    font-size: 14px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.react-datepicker__day--disabled {
    opacity: 0.3;
}

.react-datepicker__day--selected {
    background-color: #21CB7A !important;
    font-weight: bold !important;
    color: #FFFFFF !important;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.react-datepicker__day:hover {
    background-color: #E0E1E5 !important;
    color: #32335C !important;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.react-datepicker__day--disabled:hover {
    background-color: transparent !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: transparent !important;
    color: #32335C !important;
}

.react-datepicker__month {
    margin: 15px !important;
}

.react-datepicker__navigation--previous {
    border-right-color: #FFFFFF !important;
}

.react-datepicker__navigation--next {
    border-left-color: #FFFFFF !important;
}

.react-datepicker-popper[data-placement="bottom-start"] .react-datepicker__triangle {
    border-bottom-color: #21CB7A !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: #21CB7A !important;
}

.react-datepicker__triangle {
    left: initial !important;
    right: 18px !important;
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16) !important;
}

.react-datepicker-popper[data-placement="top-start"] .react-datepicker__triangle {
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16) !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
    border-top-color: #FFFFFF !important;
}

.travelSummaryCard {
    display: none !important;
}




.is-valid-input .insurance-main-select__control,
.is-valid-input input,
.is-valid-input textarea {
    border-color: #1BCD78 !important;
}

.is-valid-input .react-datepicker-wrapper:after {
    color: #1BCD78 !important;
}









@media screen and (max-width: 1600px) {

    .getPlanInstructionIcons img {
        width: 150px;
    }

    .getPlanInstructionText {
        font-size: 17px;
    }

    .getPlanInstructionBlock {
        height: 85px;
    }

}


@media screen and (max-width: 1199px) {

    .getPlanInstructionIcons img {
        width: 130px;
        position: relative;
        top: 2px;
    }

    .getPlanInstructionText {
        font-size: 15px;
        line-height: 24px;
    }

    .getPlanInstructionIcons {
        margin-right: 35px;
    }

    .getPlanInstructionBlock {
        height: 70px;
    }

    .userDataContainer {
        padding: 30px 0 60px 25px;
    }

    .getPlanPageRightPart {
        padding-left: 0;
    }

    .summaryCardBody {
        padding: 25px 20px;
    }

    .summaryCardAdditionalOptionsBlock {
        padding: 0 15px;
    }

    .summaryCardTotalPrice {
        padding: 15px 20px;
    }

    .summaryCardTotalPriceTitle {
        font-size: 16px;
    }

    .summaryCardTotalPriceValue {
        font-size: 19px;
    }

    .summaryCardAdditionalOptions {
        font-size: 12px;
        line-height: 16px;
    }

    .summaryCard .payment-frequency-option .insurance-main-select__single-value {
        font-size: 14px;
    }

    .summaryCardTypeOfCoverageList ul {
        grid-column-gap: 0px;
        column-gap: 0px;
    }

    .summaryCardTypeOfCoverageList ul li {
        font-size: 12px;
    }

    .summaryCardLogo {
        min-height: 90px;
    }

    .summaryCardLogo img {
        max-height: 80px;
    }

    .summaryCardTypeOfCoverageList {
        padding: 15px 0;
    }

    .summaryCardViewButton .viewPlanButton {
        height: 45px;
        font-size: 18px;
    }

    .summaryCardBody {
        padding-top: 15px;
    }

    .travelSummaryName {
        font-size: 14px;
    }

    .travelSummaryValue {
        font-size: 16px;
        line-height: 20px;
    }

    .goToResultPage {
        height: 55px;
        line-height: 55px;
        margin: 15px 0;
    }

    .userDataCardSubTitle {
        font-size: 15px;
    }

    .userDataCard .inputBox input {
        /*padding: 13px 15px;*/
    }

}


@media screen and (max-width: 992px) {

    .getPlanPageLeftPart {
        -ms-flex: 0 0 333px;
        flex: 0 0 333px;
        max-width: 333px;
    }

    .getPlanPageRightPart {
        -ms-flex: 0 0 calc(100% - 333px);
        flex: 0 0 calc(100% - 333px);
        max-width: calc(100% - 333px);
    }

    .userDataContainer {
        padding: 30px 0 60px 15px;
    }

    .userDataCard {
        padding: 25px;
    }

    .insureesListContainer .col-8 {
        padding-right: 8px;
    }

    .insureesListContainer .col-4 {
        padding-left: 8px;
    }

    .userDataCard .inputBox label {
        padding-left: 15px;
    }

    .userDataCard .inputBox input {
        padding: 13px 15px;
    }

    .userDataCard .react-tel-input input {
        padding-left: 55px;
    }

    .userDataCard .col-6:first-child {
        padding-right: 8px;
    }

    .userDataCard .col-6:last-child {
        padding-left: 8px;
    }

    .getPlanInstructionText {
        font-size: 14px;
        line-height: 18px;
        max-width: 390px;
    }

    .mobileLinkHeaderButton {
        /* TODO: тимчасове рішення*/
        position: fixed;
        z-index: 5;
        top: 15px;
        right: 25px;

        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #32335C;
        background-color: #FFFFFF;
        border: 1px solid #E0E1E5;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 3px 16px 4px 9px;
        height: 32px;
        float: right;

        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }

    .mobileLinkHeaderButton span {
        color: #1BCD78;
        margin-right: 19px;
        font-size: 19px;
        top: 3px;
        position: relative;
    }

    .mobileLinkHeaderButton:hover {
        border-color: #1BCD78;
        box-shadow: 0px 0px 26px 0px rgba(33,203,122,0.5);
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }

    .goToResultPage {
        display: none;
    }

    .insurancePlanCard {
        margin-top: 30px;
    }

}


@media screen and (max-width: 767px) {

    .getPlanPageLeftPart,
    .getPlanPageRightPart,
    .dateInputCol {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .getPlanInstructionIcons,
    .getPlanInstructionText {
        width: 100%;
        max-width: 100%;
        display: block;
    }

    .getPlanInstructionBlock {
        display: block;
        height: auto;
        justify-content: initial;
        align-items: initial;
        text-align: center;
        padding: 11px 25px;
    }

    .getPlanPageContainer {
        padding: 0 25px;
        background-color: #F7F8FC;
    }

    .getPlanInstructionIcons img {
        width: 145px;
    }

    .getPlanInstructionIcons {
        margin-bottom: 6px;
    }

    .getPlanInstructionText {
        font-size: 14px;
        line-height: 20px;
    }

    .getPlanInstructionText span {
        font-weight: normal;
    }

    .summaryCardBody {
        padding: 11px 15px;
    }

    .travelSummaryCard .summaryCardBody {
        padding: 20px 15px;
    }

    .getPlanPageRightPart {
        padding-left: 15px;
    }

    .userDataContainer {
        padding: 0;
        /*margin-top: 20px;*/
        margin-bottom: 50px;
    }

    .userDataCard {
        padding: 17px 15px;
    }

    .dateInputCol .inputBox {
        /*margin-bottom: 0;*/
    }

    .userDataCardSubTitle {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .userDataCard .col-5,
    .userDataCard .col-6,
    .userDataCard .col-7{
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 15px !important;
    }

    .handleReceiveACall {
        width: 100%;
        padding: 10px 25px 10px 15px;
    }

    .userDataCard .inputBox label {
        margin-bottom: 9px;
    }

    .summaryCardLogo {
        min-height: 110px;
    }

    .summaryCardLogo img {
        max-height: 100px;
    }

    .userDataCard .inputBox label {
        padding-left: 12px;
    }

    .userDataCard .inputBox input {
        padding: 13px 12px;
    }

    .userDataCard .react-tel-input input {
        padding-left: 55px;
    }

    .dateInputCol label {
        padding-left: 8px !important;
    }

    .summaryCard .payment-frequency-option .insurance-main-select__single-value {
        font-size: 16px;
    }

    .summaryCardBody .paymentFrequencyBlock .payment-frequency-option {
        width: 140px;
    }

    .summaryCardBody .paymentFrequencyBlock label {
        width: calc(100% - 140px);
    }

    .summaryCardTypeOfCoverageList ul li {
        font-size: 14px;
    }

    .mobileLinkHeaderButton {
        /* TODO: тимчасове рішення*/
        top: 11px;
        right: 25px;
    }

    .getPlanPageContainer {
        padding-bottom: 50px;
    }

    .compareFixedModalTitle {
        width: 105px;
        float: left;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
        padding-right: 5px;
    }

    .compareFixedModalButtons {
        width: calc(100% - 105px);
        float: right;
    }

    .compareFixedModalBlock {
        padding: 10px 15px;
    }

    .comparePlansButton,
    .uncheckAllPlansButton {
        font-size: 14px;
        height: 34px !important;
        line-height: 34px;
    }

    .uncheckAllPlansButton span {
        font-size: 10px;
    }

    .compareFixedModalBlock {
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

}


@media screen and (max-width: 370px) {

    .getPlanPageContainer {
        padding: 0 15px;
    }

    .comparePlansButton, .uncheckAllPlansButton {
        font-size: 13px;
    }

}


@media screen and (max-width: 350px) {

    .summaryCardTypeOfCoverageList ul li {
        font-size: 13px;
    }

    .mobileLinkHeaderButton span {
        margin-right: 10px;
    }

    .summaryCardTotalPriceTitle {
        font-size: 15px;
    }

    .compareFixedModalTitle {
        width: 81px;
        font-size: 11px;
    }

    .compareFixedModalButtons {
        width: calc(100% - 81px);
    }

    .comparePlansButton {
        margin-right: 5px;
    }

    .comparePlansButton, .uncheckAllPlansButton {
        font-size: 12px;
    }

    .compareFixedModalBlock {
        padding: 10px;
    }

    .uncheckAllPlansButton span {
        font-size: 9px;
    }

}

/* Checkbox */

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    .checkbox-wrapper-13 input[type=checkbox] {
        --active: #fff;
        --active-inner: #0AD675;
        --focus: 2px #E0E1E5;
        --border: #BBC1E1;
        --border-hover: #275EFE;
        --background: #fff;
        --disabled: #F6F8FF;
        --disabled-inner: #E1E6F9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid #0AD675;
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    }
    .checkbox-wrapper-13 input[type=checkbox]:after {
        content: "";
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
    }
    .checkbox-wrapper-13 input[type=checkbox]:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    .checkbox-wrapper-13 input[type=checkbox]:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: 0.9;
    }
    .checkbox-wrapper-13 input[type=checkbox]:disabled:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
    }
    .checkbox-wrapper-13 input[type=checkbox]:disabled + label {
        cursor: not-allowed;
    }
    .checkbox-wrapper-13 input[type=checkbox]:hover:not(:checked):not(:disabled) {
        --bc: var(--border-hover);
    }
    .checkbox-wrapper-13 input[type=checkbox]:focus {
        box-shadow: 0 0 0 var(--focus);
    }
    .checkbox-wrapper-13 input[type=checkbox]:not(.switch) {
        width: 21px;
    }
    .checkbox-wrapper-13 input[type=checkbox]:not(.switch):after {
        opacity: var(--o, 0);
    }
    .checkbox-wrapper-13 input[type=checkbox]:not(.switch):checked {
        --o: 1;
    }
    .checkbox-wrapper-13 input[type=checkbox] + label {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        margin-left: 4px;
    }

    .checkbox-wrapper-13 input[type=checkbox]:not(.switch) {
        border-radius: 7px;
    }
    .checkbox-wrapper-13 input[type=checkbox]:not(.switch):after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
    }
    .checkbox-wrapper-13 input[type=checkbox]:not(.switch):checked {
        --r: 43deg;
    }
}

.checkbox-wrapper-13 * {
    box-sizing: inherit;
}
.checkbox-wrapper-13 *:before,
.checkbox-wrapper-13 *:after {
    box-sizing: inherit;
}


/* Checkbox */
