@font-face {
  font-family: Poppins;
  src: url('./assets/fonts/Poppins-Black.woff2') format('woff2'),
  url('./assets/fonts/Poppins-Black.woff') format('woff'),
  url('./assets/fonts/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url('./assets/fonts/Poppins-Bold.woff2') format('woff2'),
  url('./assets/fonts/Poppins-Bold.woff') format('woff'),
  url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url('./assets/fonts/Poppins-Medium.woff2') format('woff2'),
  url('./assets/fonts/Poppins-Medium.woff') format('woff'),
  url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url('./assets/fonts/Poppins-Regular.woff2') format('woff2'),
  url('./assets/fonts/Poppins-Regular.woff') format('woff'),
  url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src:  url('./assets/fonts/icomoon.eot?l63he8');
  src:  url('./assets/fonts/icomoon.eot?l63he8#iefix') format('embedded-opentype'),
  url('./assets/fonts/icomoon.ttf?l63he8') format('truetype'),
  url('./assets/fonts/icomoon.woff?l63he8') format('woff'),
  url('./assets/fonts/icomoon.svg?l63he8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  /*speak: never;*/
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-arrow-long-right:before {
  content: "\e91e";
}
.icon-arrow-up:before {
  content: "\e91d";
}
.icon-thin-arrow-left:before {
  content: "\e91b";
}
.icon-thin-arrow-right:before {
  content: "\e91c";
}
.icon-phone-2-fill:before {
  content: "\e91a";
}
.icon-mail:before {
  content: "\e919";
}
.icon-star-three:before {
  content: "\e916";
}
.icon-star-two:before {
  content: "\e917";
}
.icon-star-one:before {
  content: "\e918";
}
.icon-close-thin:before {
  content: "\e912";
}
.icon-filter:before {
  content: "\e915";
}
.icon-phone-2:before {
  content: "\e911";
}
.icon-cup:before {
  content: "\e906";
}
.icon-message-circle:before {
  content: "\e913";
}
.icon-search:before {
  content: "\e914";
}
.icon-big-arrow-left:before {
  content: "\e910";
}
.icon-payment-frequency:before {
  content: "\e90f";
}
.icon-user-big:before {
  content: "\e90e";
}
.icon-calendar:before {
  content: "\e900";
}
.icon-check-mark:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e902";
}
.icon-compare:before {
  content: "\e903";
}
.icon-diamond:before {
  content: "\e904";
}
.icon-info:before {
  content: "\e905";
}
.icon-question:before {
  content: "\e907";
}
.icon-sort:before {
  content: "\e908";
}
.icon-trash:before {
  content: "\e909";
}
.icon-user:before {
  content: "\e90a";
}
.icon-arrow-down:before {
  content: "\e90b";
}
.icon-arrow-left:before {
  content: "\e90c";
}
.icon-big-arrow-right:before {
  content: "\e90d";
}
.icon-curruncy:before {
  content: "\e91f";
}












html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

body, html {
  height: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
  font-size: 16px;
}

input[type="submit"],
button {
  cursor: pointer;
}
input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  * {
    -webkit-text-size-adjust: none;
  }
}
table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
*:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:active,
*:focus {
  outline: none !important;
}






.expatAssuranceApp {
  min-height: 100vh;
  overflow: hidden;
}

.pageBody,
.mainPage,
.mainPageContainer {
  min-height: calc(100vh - 120px);
}




/* CUSTOM STYLE FOR RADIO INPUTS START */

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-right: 39px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;

  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #91ADC1;
}

[type="radio"]:checked + label {
  color: #1BCD78;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #1BCD78;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #1BCD78;
  position: absolute;
  top: 5.5px;
  right: 5.5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* CUSTOM STYLE FOR RADIO INPUTS END */




/* INPUT PLACEHOLDER STYLE START */

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #91ADC1 !important;
  font-weight: normal;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #91ADC1 !important;
  font-weight: normal;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #91ADC1 !important;
  font-weight: normal;
}
:-moz-placeholder { /* Firefox 18- */
  color: #91ADC1 !important;
  font-weight: normal;
}

/* INPUT PLACEHOLDER STYLE END */




.mainButtonStyle {
  font-family: Poppins;
  background-color: #21CB7A;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 31px;
  text-align: center;
  color: #FFFFFF;
  padding: 10px 55px;
  position: relative;
  z-index: 0;

  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.mainButtonStyle:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  background-color: #1BCD78;
  opacity: 0.5;
  border-radius: 5px;
  -webkit-filter: blur(20px);
  filter: blur(20px);
  z-index: -1;
  /*opacity: 0;*/
  -webkit-transform: translate(-50%, -50%);
  -moz-transform:    translate(-50%, -50%);
  -ms-transform:     translate(-50%, -50%);
  -o-transform:      translate(-50%, -50%);
  transform:         translate(-50%, -50%) scale(0);

  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.mainButtonStyle:hover {
  background-color: #21CB7A;

  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.mainButtonStyle:hover:after {
  -webkit-transform: translate(-50%, -50%);
  -moz-transform:    translate(-50%, -50%);
  -ms-transform:     translate(-50%, -50%);
  -o-transform:      translate(-50%, -50%);
  transform:         translate(-50%, -50%) scale(1);

  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.secondaryButtonStyle {
  font-family: Poppins;
  background-color: #FFFFFF;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 31px;
  text-align: center;
  color: #21CB7A;
  padding: 10px 55px;
  position: relative;
  border: 1px solid #21CB7A;

  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.secondaryButtonStyle:hover {
  box-shadow: 0px 0px 26px 0px rgba(33,203,122,0.5);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.form-validation-error .not-valid-input input,
.form-validation-error .not-valid-input .insurance-main-select__control {
  border-color: #FF5353 !important;
}

.insurance-main-select__control {
  cursor: pointer !important;
}

.insurance-main-select__control:hover {
  box-shadow: 0px 0px 5px rgba(27, 205, 120, 0.5) !important;
}

.not-allowed {
  cursor: not-allowed !important;
}














/* FULL WINDOW MODAL STYLE START */
.full-window-modal {
  max-width: initial;
  margin: 0;
  width: 100%;
  height: auto;
}

.full-window-modal .modal-content {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  border-radius: 0;
  padding-bottom: 94px;
  border: none !important;
}

.modal.fade .full-window-modal {
  transition: -webkit-transform .7s ease-out;
  transition: transform .7s ease-out;

  -webkit-transform: translate3d(100vw,0,0);
  transform: translate3d(100vw,0,0);

}

.modal.show .full-window-modal {
  -webkit-transform: none !important;
  transform: none !important;
}

.full-window-modal .modal-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 51px;
  text-align: center;
  color: #32335C;
  width: 100%;
  border-color: #1FCB79;
}

.full-window-modal .close-modal-button {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 50px;
  padding: 0 20px 0 10px;
  height: 50px;
  color: #1BCD78;
  position: absolute;
  top: 50%;
  background-color: transparent;
  border: 1px solid transparent;
  -webkit-transform: translateY(-50%);
  -moz-transform:    translateY(-50%);
  -ms-transform:     translateY(-50%);
  -o-transform:      translateY(-50%);
  transform:         translateY(-50%);

  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.full-window-modal .close-modal-button .icon-big-arrow-left {
  margin-right: 20px;
  font-size: 24px;
  position: relative;
  top: 5px;
}

.full-window-modal .close-modal-button:hover {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-color: #1BCD78;
}

.full-window-modal .modal-header {
  position: relative;
  height: 103px;
  align-items: center;
  border-color: #03D773;
  border-radius: 0 !important;
}

.full-window-modal .modal-header .close {
  display: none;
}

.full-window-modal .modal-body {
  padding-top: 30px;
}

.modal-open .modal {
  padding-left: 0 !important;
}

/* FULL WINDOW MODAL STYLE END */





/* LEFT ALIGN WINDOW MODAL STYLE START */

.modal.fade .left-align-window-modal {
  transition: -webkit-transform .7s ease-out;
  transition: transform .7s ease-out;

  -webkit-transform: translate3d(-100vw,0,0);
  transform: translate3d(-100vw,0,0);

}

.modal.show .left-align-window-modal {
  -webkit-transform: none !important;
  transform: none !important;
}

.left-align-window-modal .modal-header {
  max-width: 400px !important;
}

.left-align-window-modal .modal-content {
  max-width: 400px !important;
}

/* LEFT ALIGN WINDOW MODAL STYLE END */



/* WARNING CENTERED WINDOW MODAL STYLE START */

.warning-centered-modal {
  max-width: 570px;
}

.warning-centered-modal .modal-content {
  padding: 45px 50px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
  box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
  border-radius: 15px;
  border: none;
}

.warning-centered-modal .modal-header {
  border: none;
  padding: 0;
}

.warning-centered-modal .modal-header .close {
  display: none !important;
}

.warning-centered-modal .modal-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #32335C;
  margin-bottom: 22px;
}

.warning-centered-modal .modal-title span {
  font-size: 26px;
  color: #FF5353;
  margin-bottom: 20px;
  display: block;
}

.warning-centered-modal .modal-body {
  padding: 0;
  text-align: center;
}

.warning-centered-modal .modal-body {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #91ADC1;
}

.warning-centered-modal .secondaryButtonStyle {
  margin-top: 23px;
}

/* WARNING CENTERED WINDOW MODAL STYLE END */





/* CONTACT FORM CENTERED WINDOW MODAL STYLE START */

.contact-form-centered-modal {
  max-width: 720px;
  border: none;
}

.contact-form-centered-modal .modal-content {
  box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
  border-radius: 15px;
  border: none;
}

.contact-form-centered-modal .modal-header .close {
  display: none !important;
}

.contact-form-centered-modal .modal-header {
  padding: 0;
  background-color: transparent;
  border: none;
}

.contact-form-centered-modal .modal-body {
  padding: 0;
}

.contact-form-centered-modal .contactInformationCard {
  border-radius: 15px;
  box-shadow: none;
}

.contact-form-centered-modal .modal-header .close-modal {
  position: absolute;
  right: -30px;
  top: -30px;
  z-index: 2;
  color: #FF5353;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #FFFFFF;
  border: 1px solid #FF5353;
  box-shadow: 0px 5px 10px rgba(182, 26, 26, 0.3);

  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.contact-form-centered-modal .modal-header .close-modal span:before {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 14px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform:    translate(-50%, -50%);
  -ms-transform:     translate(-50%, -50%);
  -o-transform:      translate(-50%, -50%);
  transform:         translate(-50%, -50%);
}

.contact-form-centered-modal .modal-header .close-modal:hover {
  background-color: #FF5353;
  color: #FFFFFF;

  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}


/* CONTACT FORM CENTERED WINDOW MODAL STYLE END */




/* GATING MODAL STYLE START */

.gating-modal .modal-content {
  padding: 36px;
}

.gatingSubTitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #1BCD78;
  margin-bottom: 20px;
}

.gatingDesc {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #494949;
  margin-top: 13px;
  margin-bottom: 16px;
}

.gatingInputs input {
  border: 1px solid #1BCD78;
  width: 100%;
  border-radius: 10px;
  padding: 0 35px;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #494949;
  max-width: 370px;
  width: 100%;
}

.gatingInputs input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #B6B6B6;
}
.gatingInputs input::-moz-placeholder { /* Firefox 19+ */
  color: #B6B6B6;
}
.gatingInputs input:-ms-input-placeholder { /* IE 10+ */
  color: #B6B6B6;
}
.gatingInputs input:-moz-placeholder { /* Firefox 18- */
  color: #B6B6B6;
}

.gatingFormButton {
  background-color: #1BCD78;
  color: #FFFFFF;
}

.gating-error {
  border-color: #ff5353 !important;
  box-shadow: 0px 0px 5px rgba(255, 83, 83, 0.5);
}

.input-error-bounce {
  outline: 0;
  border-color: red;
  animation-name: input-error-bounce;
  animation-duration: .5s;
  animation-delay: 0.25s;
}

/* This approximates the ease-in-out-bounce animation from easings.net, which would require a plug-in to use*/
@keyframes input-error-bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}

/* GATING MODAL STYLE END */







.loader {
  margin-top: 10px;
}






@media screen and (min-width: 1200px) {

  .defaultContainer,
  .defaultHeaderStyle .container {
    max-width: 1200px;
  }

}


@media screen and (min-width: 992px) {

  .only-desktop {
    display: block !important;
  }

  .only-mobile {
    display: none !important;
  }

  .smallContainer,
  .mainPageHeaderStyle .container {
    max-width: 1000px;
  }

}


@media screen and (max-width: 1199px) {

  .full-window-modal .modal-title {
    font-size: 25px;
  }

  .full-window-modal .modal-header {
    height: 65px;
  }

  .full-window-modal .close-modal-button {
    line-height: 38px;
    height: 40px;
  }

}


@media screen and (max-width: 991px) {

  .only-desktop {
    display: none !important;
  }

  .only-mobile {
    display: block !important;
  }

}


@media screen and (max-width: 992px) {

  .full-window-modal .modal-header {
    height: 65px;
    position: fixed;
    width: 100%;
    background-color: #FFFFFF;
    z-index: 5;
    top: 0;
    left: 0;
  }

  .full-window-modal .modal-content {
    padding-top: 65px;
  }

  .contact-form-centered-modal .modal-header .close-modal {
    right: 5px;
    top: 5px;
  }

}


@media screen and (max-width: 767px) {

  .close-modal-button-title {
    display: none;
  }

  .full-window-modal .close-modal-button {
    background-color: #FFFFFF;
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    padding: 0 10px 0 25px;
  }

  .full-window-modal .modal-header,
  .full-window-modal .modal-header .defaultContainer {
    padding: 0;
  }

  .full-window-modal .close-modal-button .icon-big-arrow-left {
    margin-right: 0;
  }

  .full-window-modal .modal-content {
    padding-top: 57px;
  }

  .full-window-modal .modal-header {
    height: 57px;
  }

  .full-window-modal .modal-title {
    font-size: 18px;
  }

  .warning-centered-modal {
    padding: 0 15px;
  }

  .warning-centered-modal .modal-content {
    padding: 20px 15px;
  }

  .warning-centered-modal .secondaryButtonStyle {
    padding: 10px 0;
    width: 100%;
  }

  .full-window-modal .modal-body {
    padding: 30px 25px 0 25px;
  }

  .gatingInputs input {
    padding: 0 15px;
    font-size: 16px;
  }

  .gating-modal .modal-content {
    padding: 20px 25px;
  }

}



@media screen and (max-width: 370px) {

  .full-window-modal .modal-body {
    padding: 20px 15px 0 15px;
  }

  .modal-dialog-centered {
    padding: 0 !important;
  }

  .gating-modal .modal-content {
    padding: 20px 15px;
  }

}

@media screen and (max-width: 350px) {

  .full-window-modal .close-modal-button {
    padding: 0 7px 0 12px;
  }

}

