.mainPageHeaderStyle .headerContainer {
    padding-top: 52px;
    height: 120px;
    padding-bottom: 33px;
    position: relative;
}

.defaultHeaderStyle .headerContainer {
    /*padding-top: 21px;*/
    /*padding-bottom: 33px;*/
    height: 80px;
    position: relative;
}

.defaultHeaderStyle .headerContainer img {
    width: 140px;
    top: 2px;
    position: relative;
}

.defaultHeaderStyle .headerContainer .row {
    height: 100%;
}

.defaultHeaderStyle {
    border-bottom: 1px solid #1FCB79;
    background-color: #FFFFFF;
}

.appLogoBlock,
.headerButtonsPart {
    align-self: center;
}

.headerButtonsPart {
    text-align: right;
}

.headerContactFormButton {
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    margin-left: 20px;
}

.headerContactFormButton .icon-mail {
    margin-right: 10px;
    position: relative;
    top: 1px;
    font-size: 17px;
}

.phoneButton {
    margin-left: 20px;
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    color: #32335C;
    border-color: #E0E1E5;

    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.phoneButton:hover {
    color: #1BCD78;
    border-color: #1BCD78;
}

.phoneButton span{
    margin-right: 8px;
}

.etreRappeleButton {
    margin-left: 8px;
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    color: #32335C;
    border-color: #E0E1E5;

    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.etreRappeleButton:hover {
    color: #1BCD78;
    border-color: #1BCD78;
}

.etreRappeleButton span {
    position: absolute;
    background: #21CB7A;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    top: -11px;
    right: -11px;
    color: #ffffff;
    border: 3px solid #ffffff;
    font-size: 10px;
}

.etreRappeleButton span:before {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform:    translate(-50%, -50%);
    -ms-transform:     translate(-50%, -50%);
    -o-transform:      translate(-50%, -50%);
    transform:         translate(-50%, -50%);
}

.etreRappeleModalText {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #32335C;
    max-width: 600px;
    margin: 15px auto 30px auto;
}

.phoneButton,
.etreRappeleButton,
.headerContactFormButton {
    transition-property: box-shadow !important;
}




/* SLIDE IN MODAL STYLE START */

.slideInModalBlock {
    position: fixed;
    z-index: 5;
    right: 30px;
    bottom: 30px;
    max-width: 380px;
    padding: 15px;
    background: #FFFFFF;
    border: 1px solid #1BCC78;
    box-sizing: border-box;
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
    border-radius: 5px;

    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(50%);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.slideInModalMessage {
    background-color: #F4F4F4;
    border-radius: 5px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #32335C;
    padding: 10px 15px;
    margin-left: 25px;
    position: relative;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.slideInModalMessage:before {
    content: '';
    position: absolute;
    left: -9px;
    top: 0;
    width: 0;
    height: 0;
    border-top: 10px solid #F4F4F4;
    border-left: 10px solid transparent;
}

.slideInModalMessage p {
    margin-bottom: 15px;
}

.slideInModalMessage p:last-child {
    margin-bottom: 0;
}

.slideInModalButtonsBlock {
    margin-top: 15px;
}

.slideInModalHandlerButton {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    height: 60px;
    line-height: 21px;
    text-align: right;
    color: #32335C;
    text-align: right;
    float: right;
    width: 313px;
    position: relative;

    background: #E4F5ED;
    border: 1px solid #1BCB79;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 15px;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}


.slideInModalHandlerButtonBody {
    position: absolute;
    right: 55px;
    top: 50%;
    width: calc(100% - 55px);

    -webkit-transform: translateY(-50%);
    -moz-transform:    translateY(-50%);
    -ms-transform:     translateY(-50%);
    -o-transform:      translateY(-50%);
    transform:         translateY(-50%);
}

.slideInModalHandlerButton:hover {
    background-color: #1BCB79;
    color: #FFFFFF;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.slideInModalHandlerButton .icon-arrow-long-right {
    color: #02D973;
    font-size: 10px;
    margin-right: 20px;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.slideInModalHandlerButton:hover span {
    color: #FFFFFF;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.slideInModalCloseButton {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    color: #32335C;
    position: relative;
    height: 60px;
    width: 313px;
    float: right;
    text-align: right;
    background-color: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 5px;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.slideInModalCloseButton .slideInModalHandlerButtonBody {
    text-decoration: underline;
}

.slideInModalCloseButton .icon-close {
    margin-right: 20px;
    text-decoration: none;
    color: #32335C;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.slideInModalAvatar {
    position: absolute;
    left: -35px;
    top: -25px;
    background-color: #FFFFFF;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    box-shadow: -30px 5px 30px rgba(55, 87, 124, 0.16);
}

.slideInModalAvatar img {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform:    translate(-50%, -50%);
    -ms-transform:     translate(-50%, -50%);
    -o-transform:      translate(-50%, -50%);
    transform:         translate(-50%, -50%);
}

.slideInModalCloseButton:hover {
    border-color: #02D973;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.slideInModalCloseButton:hover .icon-close {
    color: #02D973;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}


.slideInModalBlock.is-visible {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}



.slide-modal-animate-wrapper-1,
.slide-modal-animate-wrapper-2,
.slide-modal-animate-wrapper-3,
.slide-modal-animate-wrapper-4 {
    opacity: 0;
    transform: translateY(50px);
}


.slideInModalBlock.is-visible .slide-modal-animate-wrapper-1 {
    opacity: 1;
    transform: translateY(0);



    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;

    transition-delay: 0.5s;
}

.slideInModalBlock.is-visible .slide-modal-animate-wrapper-2 {
    opacity: 1;
    transform: translateY(0);



    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;

    transition-delay: 2.6s;
}

.slideInModalBlock.is-visible .slide-modal-animate-wrapper-3 {
    opacity: 1;
    transform: translateY(0);



    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;

    transition-delay: 2.8s;
}

.slideInModalBlock.is-visible .slide-modal-animate-wrapper-4 {
    opacity: 1;
    transform: translateY(0);



    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;

    transition-delay: 3s;
}

.slideInModalBlockOverlayForMobile {
    display: none;
}



/* SLIDE IN MODAL STYLE END */






















@media screen and (min-width: 1024px) and (min-height: 800px) {

    .slideInModalMessage {
        font-size: 14px;
        line-height: 21px;
    }

    .slideInModalBlock {
        max-width: 410px;
        padding: 20px 15px;
    }

    .slideInModalMessage p {
        margin-bottom: 25px;
    }

    .slideInModalCloseButton {
        font-size: 14px;
        line-height: 21px;
    }

}



@media screen and (max-width: 1199px) {

    .defaultHeaderStyle .headerContainer img {
        width: 110px;
        top: 3px;
    }

    .defaultHeaderStyle .headerContainer {
        height: 65px;
    }

    .headerContactFormButton {
        font-size: 17px;
    }

    .phoneButton,
    .etreRappeleButton,
    .headerContactFormButton {
        height: 40px;
        line-height: 40px;
    }

}


@media screen and (min-width: 992px) and (max-height: 650px) {

    .slideInModalMessage {
        font-size: 12px;
        line-height: 18px;
    }

}


@media screen and (max-width: 992px) {

    .defaultHeaderStyle .headerContainer {
        position: fixed;
        z-index: 5;
        background-color: #FFFFFF;
        left: 0;
        top: 0;
        width: 100%;
        border-bottom: 1px solid #1FCB79;
    }

    .resultPage,
    .getPlanPage {
        padding-top: 65px;
    }

    .headerContactFormButtonText {
        display: none;
    }

    .headerContactFormButton {
        position: fixed;
        right: 30px;
        bottom: 30px;
        display: block;
        width: 53px;
        height: 53px;
        border-radius: 50%;
        background-color: #1BCD78;
        border: 1px solid #FFFFFF;
        padding: 0;
        box-shadow: 0px 5px 10px rgba(7, 121, 66, 0.4);
    }

    .headerContactFormButton .icon-mail {
        color: #FFFFFF;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform:    translate(-50%, -50%);
        -ms-transform:     translate(-50%, -50%);
        -o-transform:      translate(-50%, -50%);
        transform:         translate(-50%, -50%);
    }

    .phoneButton {
        height: 32px;
        line-height: 32px;
        margin-right: 125px;
        padding: 0 15px;
        border-color: #1FCA79;
    }

    .etreRappeleButton {
        height: 32px;
        line-height: 32px;
        margin-right: 125px;
        padding: 0 15px;
        border-color: #1FCA79;
    }

    .etreRappeleButton span {
        height: 22px;
        width: 22px;
        font-size: 8px;
    }

    .phoneButton {
        display: none;
    }

}


@media screen and (max-width: 991px) {

    .defaultHeaderStyle .headerContainer {
        padding: 0 30px;
    }

}


@media screen and (min-width: 768px) {

    .compareFixedModalTitle.only-desktop {
        display: block !important;
    }

    .compareFixedModalTitle.only-mobile {
        display: none !important;
    }

}


@media screen and (max-width: 767px) {

    .defaultHeaderStyle .headerContainer {
        height: 57px;
        padding: 0 25px;
    }

    .defaultHeaderStyle .headerContainer img {
        width: 76px;
    }

    .defaultHeaderStyle {
        border: none;
    }

    .defaultHeaderStyle .headerContainer {
        position: fixed;
        z-index: 5;
        background-color: #FFFFFF;
        left: 0;
        top: 0;
        width: 100%;
        border-bottom: 1px solid #1FCB79;
    }

    .resultPage,
    .getPlanPage {
        padding-top: 57px;
    }

    .etreRappeleButton {
        margin-right: 130px;
    }

    .appLogoBlock {
        -ms-flex: 0 0 80px;
        flex: 0 0 80px;
        max-width: 80px;
    }

    .headerButtonsPart {
        -ms-flex: 0 0 calc(100% - 80px);
        flex: 0 0 calc(100% - 80px);
        max-width: calc(100% - 80px);
    }




    /* SLIDE IN MODAL STYLE START */

    .slideInModalBlock {
        max-width: 350px;
        right: 15px;
        bottom: 15px;
    }

    .slideInModalHandlerButton,
    .slideInModalCloseButton {
        width: 91%;
    }

    .slideInModalHandlerButton {
        font-size: 12px;
        line-height: 17px;
    }

    .slideInModalBlockOverlayForMobile {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 6;
        width: 100%;
        height: 100%;

        background: rgba(248, 250, 252, 0.2);
        backdrop-filter: blur(15px);
    }

    .slideInModalHandlerButtonBody {
        right: 50px;
    }

    .slideInModalHandlerButton .icon-arrow-long-right {
        margin-right: 15px;
    }

    .slideInModalBlock {
        z-index: 7;
    }

    .slideInModalBlockOverlayForMobile.is-visible {
        display: block;
    }

    /* SLIDE IN MODAL STYLE END */


    .compareFixedModalTitle.only-desktop {
        display: none !important;
    }

    .compareFixedModalTitle.only-mobile {
        display: block !important;
    }


    .headerContactFormButton {
        transition-property: bottom !important;
        transition-duration: 0.3s !important;
        transition-delay: 0.5s !important;
    }


    .headerContactFormButton.is-visible {
        bottom: 70px;
    }




}


@media screen and (max-width: 767px) and (max-height: 600px) {

    .slideInModalMessage {
        line-height: 17px;
    }

    .slideInModalMessage p {
        margin-bottom: 10px;
    }

    .slideInModalBlock {
        max-width: 335px;
    }

    .slideInModalHandlerButton, .slideInModalCloseButton {
        width: 92%;
        font-size: 12px;
        line-height: 16px;
        height: 50px;
    }

    .slideInModalAvatar {
        left: -25px;
        width: 60px;
        height: 60px;
    }

    .slideInModalAvatar img {
        width: 50px;
        height: 50px;
    }

}


@media screen and (max-width: 480px) {

    .mobileFilterButton {
        padding: 2px 5px 4px 5px !important;
    }

    .etreRappeleButton {
        padding: 0 10px;
        font-size: 14px;
        margin-right: 97px;

    }

    .mobileFilterButton span {
        margin-right: 1px !important;
        font-size: 20px !important;
        top: 3px !important;
    }

    .defaultHeaderStyle .headerContainer img {
        top: 4px;
    }

}


@media screen and (max-width: 400px) {

    .slideInModalBlock {
        max-width: 320px;
        right: 15px;
        bottom: 15px;
    }

    .slideInModalMessage {
        font-size: 13px;
        line-height: 19px;
    }

}

@media screen and (max-width: 400px) and (max-height: 630px) {

    .slideInModalMessage {
        font-size: 12px;
        line-height: 18px;
    }

}


@media screen and (max-width: 400px) and (max-height: 590px) {

    .slideInModalMessage {
        font-size: 12px;
        line-height: 17px;
    }

}


@media screen and (max-width: 370px) {

    .mobileFilterButton {
        right: 15px !important;
    }

    .defaultHeaderStyle .headerContainer {
        padding: 0 15px;
    }

}


@media screen and (max-width: 350px) {

    .mobileFilterButton {
        line-height: 31px !important;
        padding-top: 0 !important;
        font-size: 14px !important;
    }

    .etreRappeleButton {
        padding: 0 6px;
        margin-right: 88px;
    }


    .slideInModalBlock {
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    .slideInModalAvatar {
        top: 12px;
        left: 2px;
        width: 35px;
        height: 35px;
    }

    .slideInModalAvatar img {
        width: 30px;
        height: 30px;
    }

    .slideInModalHandlerButton, .slideInModalCloseButton {
        font-size: 11px;
        line-height: 14px;
        height: 40px;
    }

    .slideInModalMessage {
        font-size: 11px;
    }

}


/* Legal  */
.legalCol p {
    padding-top: 10px;
    color: #91ADC1;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 8.8px */
}

.legalCol p a {
    color: #0AD675;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-decoration-line: underline;
}

@media screen and (max-width: 767px){
    .bottomRow {
        /*flex-direction: column-reverse;*/
    }
    .bottomRow .col-9,
    .bottomRow .col-3{
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/* Legal  */
