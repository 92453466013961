.tooltip-container {
    max-width: 500px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 21px;
    padding: 17px !important;
    -webkit-box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
    border-radius: 10px !important;
    border: 1px solid #91ADC1 !important;
    z-index: 9999 !important;
}

.tooltip-container.show {
    opacity: 1 !important;
}

.tooltip-icon {
    color: #91ADC1;
    position: relative;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.tooltip-icon:hover {
    color: #1BCD78;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.warning-tooltip {
    background-color: #FF5353 !important;
    color: #FFFFFF !important;
    border: none !important;
}

.warning-tooltip:before {
    border-top-color: #FF5353 !important;
}

.warning-tooltip:after {
    display: none !important;
}












@media screen and (max-width: 767px) {

    .tooltip-container {
        max-width: 250px;
    }

}