@media screen and (max-width: 768px) {
    .ES_reset_style_class .inputBox label {
        font-size: 13px;
    }
    .ES_reset_style_class .waveBg2 {
        left: -99%;
    }
    .ES_reset_style_class .userDataCardTitle {
        padding-top: 20px;
    }
    .ES_reset_style_class .compareFixedModalButtons {
        margin-top: 10px;
    }
}

@media screen and (max-width: 320px) {
    .ES_reset_style_class .waveBg2 {
        left: -110%;
    }
    .ES_reset_style_class .testingVersionForMainForm {
        padding: 9px;
    }
    .ES_reset_style_class .etreRappeleButton {
        font-size: 11px;
    }
    .ES_reset_style_class .viewPlanButton {
        font-size: 13px;
    }
    .ES_reset_style_class .minimizedPlanVersion .viewPlanButton span {
        margin-left: 9px;
    }
    .ES_reset_style_class .minimizedPlanVersion .viewPlanButton {
        line-height: 31px;
    }
    .ES_reset_style_class .coverageSumTitle,
    .ES_reset_style_class .summaryCardTotalPriceTitle,
    .ES_reset_style_class .planPriceTitle{
        line-height: 18px!important;
    }
    .ES_reset_style_class .minimizedPlanVersion .viewPlanButton,
    .ES_reset_style_class .minimizedPlanVersion .compare-check-button{
        width: calc(50% - 4px);
    }
    .ES_reset_style_class .minimizedPlanVersion .compare-check-button {
        margin-left: 8px;
    }
}
