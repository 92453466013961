.plansContainer img {
    width: 100%;
    margin-bottom: -45px;
}

.insurancePlansAmountBlock {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    text-align: center;
    color: #32335C;
}

.insurancePlansAmountBlock span {
    color: #1BCD78;
}

.insurancePlansAmountBlock p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #32335C;
    padding-top: 4px;
}

.insurancePlansSortAndOptionsContainer {
    padding-top: 0;
    padding-bottom: 26px;
    position: relative;
    z-index: 2;
}

/*.insurancePlansSortAndOptionsContainer .col-6,*/
.insurancePlansSortAndOptionsContainer .col-2,
.insurancePlansSortAndOptionsContainer .col-4{
    padding-left: 0!important;
    padding-right: 0!important;
}

.insurancePlansSortAndOptionsContainer .col-6 {
    padding: 0;
}

.sortBlock{
    float: left!important;
}

.paymentFrequencyBlock {
    /*width: 380px;*/
    float: right;
    /*width: 100%;*/
    margin-top: 25px;
}

.paymentFrequencyBlock label {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
    padding-right: 15px;
    color: #32335C;
    display: inline-block;
    float: left;
    width: calc(100% - 150px);
}

.insurancePlansSortAndOptionsContainer .paymentFrequencyBlock label {
    text-align: right;
}

.paymentFrequencyBlock label span {
    color: #1BCD78;
    font-size: 16px;
    position: relative;
    top: 1px;
    margin-right: 4px;
}

/* PAYMENT FREQUENCY OPTION STYLE START  */

.paymentFrequencyBlock .payment-frequency-option {
    width: 150px;
    float: right;
    top: -2px;
}

.paymentFrequencyBlock .insurance-main-select__control {
    height: 32px;
    min-height: 32px;
}

.paymentFrequencyBlock .insurance-main-select__value-container {
    padding-left: 12px !important;
}

.paymentFrequencyBlock .insurance-main-select__value-container {
    height: 32px;
}

.paymentCurrencyBlock {
    float: left;
    margin-top: 24px;
    /*padding-left: 30px;*/
}
.paymentCurrencyBlock label {
    width: calc(100% - 90px);
}

.paymentCurrencyBlock .payment-frequency-option {
    width: 90px;
    float: right;
    top: -2px;
}

.payment-frequency-option .insurance-main-select__single-value {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #32335C !important;
    top: 47%;
}

.payment-frequency-option .insurance-main-select__indicator {
    padding-right: 12px !important;
    padding-left: 9px !important;
}

/* PAYMENT FREQUENCY OPTION STYLE END  */


.plans-filters-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

@media screen and (max-width: 767px){

    .plans-filters-box {
        flex-wrap: wrap;
    }

    .plans-filters-box:nth-child(3){
        justify-self: flex-end;
        width: 100%;
    }

}

.filterCard {
    background: #FFFFFF;
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
    border-radius: 15px;
    margin-bottom: 20px;
    padding: 13px 18px 24px 18px;
    margin-top: 17px;
}

.filterCard .row:last-child .inputBoxResultPage {
    border-bottom: none !important;
    padding-bottom: 0;
}

.travelInfoCard {
    margin-bottom: 130px;
}

.filterCardTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 19px;
    color: #32335C;
    padding-bottom: 9px;
    border-bottom: 1px solid #E0E1E5;
}

.inputBoxResultPage {
    margin-top: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E0E1E5;
}

.inputBoxTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #1BCD78;
    margin-bottom: 10px;
}

.insureesRowResultPage .col-7 {
    padding-right: 9px;
}

.insureesRowResultPage .col-5 {
    padding-left: 9px;
}

.travelInfoCard .col-6 {
    padding-right: 7.5px;

    -ms-flex: 54%;
    flex: 0 0 54%;
    max-width: 54%;
}

.travelInfoCard .col-4 {
    padding-right: 0;
    padding-left: 7.5px;

    -ms-flex: 27%;
    flex: 0 0 27%;
    max-width: 27%;
}

.travelInfoCard .col-2 {
    padding: 0;
    text-align: center;

    -ms-flex: 0 0 19%;
    flex: 0 0 19%;
    max-width: 19%;
}

.travelInfoCard .deleteDependentButton {
    height: 40px;
    font-size: 20px;
    width: 35px;
    float: none;
}

.travelInfoCard .addDependentsButton {
    height: 40px;
    line-height: 15px;
    font-size: 12px;
    float: none;
    margin-top: 0;
    width: 35px;
}

.travelInfoCard .dependentsContainer .row:first-child {
    margin-top: 10px;
}

.travelInfoCard .dependentsContainer .dependentsItemRow:last-child .inputBox {
    margin-bottom: 0 !important;
}

.resultPageRightPart {
    padding: 17px 15px 190px 45px;
    background-color: #F7F8FC;
    min-height: 100%;
    position: relative;
}

.resultPageRightPart:after {
    content: '';
    position: absolute;
    background-color: #F7F8FC;
    top: 0;
    right: -199%;
    height: 100%;
    width: 200%;
    z-index: -1;
}

.insurancePlanRow {
    margin-bottom: 13px;
}

.insurancePlanItem {
    background-color: #FFFFFF;
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
    border-radius: 15px;
    padding: 17px;
    /*-webkit-transition: all 0.3s;*/
    /*-moz-transition: all 0.3s;*/
    /*-ms-transition: all 0.3s;*/
    /*-o-transition: all 0.3s;*/
    /*transition: all 0.3s;*/
}

/*.insurancePlanItem:hover {*/
/*    -webkit-transition: all 0.3s;*/
/*    -moz-transition: all 0.3s;*/
/*    -ms-transition: all 0.3s;*/
/*    -o-transition: all 0.3s;*/
/*    transition: all 0.3s;*/
/*    transform: scale(1.02);*/
/*    box-shadow: 0px 5px 54px rgba(55, 87, 124, 0.3);*/
/*}*/

.planLogoSection {
    text-align: center;
    padding: 0;
    position: relative;
}

.planLogoSection img {
    max-width: 80px;
    max-height: 100px;
    position: absolute;
    top: 55%;
    left: 54%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform:    translate(-50%, -50%);
    -ms-transform:     translate(-50%, -50%);
    -o-transform:      translate(-50%, -50%);
    transform:         translate(-50%, -50%);
}

.planGuaranteeLevelName {
    position: absolute;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /*width: 113px;*/
    height: 23px;
    line-height: 24px;
    border-radius: 45px;
    color: #32335C;
    left: -8px;
    text-align: left;
    top: 0;
    padding-left: 8px;
    padding-right: 20px;
}

.planGuaranteeLevelName span {
    color: #FFFFFF;
    margin-right: 8px;
    top: 1px;
    position: relative;
    /*top: 50%;*/
    /*left: 9px;*/
    /*-webkit-transform: translateY(-50%);*/
    /*-moz-transform:    translateY(-50%);*/
    /*-ms-transform:     translateY(-50%);*/
    /*-o-transform:      translateY(-50%);*/
    /*transform:         translateY(-50%);*/
}

.planTypeOfCoverageSection {
    padding: 0;
    position: relative;
}

.planTypeOfCoverageSection ul {
    position: absolute;
    top: 50%;
    left: 13px;
    -webkit-transform: translateY(-50%);
    -moz-transform:    translateY(-50%);
    -ms-transform:     translateY(-50%);
    -o-transform:      translateY(-50%);
    transform:         translateY(-50%);
}

.planTypeOfCoverageSection ul li {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
}

.included-coverage {
    color: #32335C;
}

.included-coverage .icon-check-mark {
    color: #1BCD78;
    margin-right: 8px;
    font-size: 10px;
    width: 14px;
    display: inline-block;
}

.not-included-coverage {
    color: #91ADC1;
}

.not-included-coverage .icon-close {
    color: #FF5353;
    margin-right: 8px;
    font-size: 10px;
    width: 14px;
    display: inline-block;
}

.planOptionsSectionBody {
    background: #F7F8FC;
    border-radius: 5px;
    height: 100%;
    padding: 0 13px 6px 13px;
}

.planOptionsBlock {
    margin: 0 -13px 0 -13px;
    padding: 0 7px;
}

.planPriceBlock {
    padding: 7px 0;
    margin: 0 0 6px 0;
    border-bottom: 1px solid #E7EAF5;
}

.planPriceTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #32335C;
    padding: 0;

    display: flex;
    align-items: center;
}

.planPriceValue {
    text-align: right;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    text-align: right;
    color: #26AA73;
    padding: 0;
    /*top: 3px;*/
}

.planOptionItem {
    /*margin-top: 15px;*/
    cursor: pointer;
    padding: 7.5px 8px;
    border-radius: 5px;
}

.planOptionItem:hover {
    background-color: #E3F4EF;
}

.planOptionItem.is-disabled {
    /*opacity: 0.2;*/
    cursor: not-allowed;
}

.planOptionItem.is-active {
    background-color: #FFFFFF;
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
}

.planOptionItem .insurance-main-select__control {
    border-color: #91ADC1 !important;
}

.planOptionItem.is-active .insurance-main-select__control {
    border-color: #1BCD78 !important;
}

.planOptionItem.is-active .insurance-main-select__control {
    border-color: #1BCD78 !important;
}

.planOptionItem.is-active .insurance-main-select__indicator {
    color: #1BCD78 !important;
}

.planOptionItemSelectInput {
    padding-left: 0;
}

.planButtonsSection {
    padding-left: 0;
}

.getPlanButton {
    padding: 0;
    height: 41px;
    width: 100%;
    margin-bottom: 19px;
    margin-top: 30px;
}

.viewPlanButton {
    height: 41px;
    line-height: 41px;
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    color: #32335C;
    border-color: #E0E1E5;

}

.viewPlanButtonLinkLike{
    border: none;
    text-decoration: underline;
    background-color: transparent;
}

.viewPlanButtonLinkLike:hover {
    box-shadow: none!important;
    color: rgb(34 203 122);
}

.viewPlanButton {
    width: 75px;
}














/* DISABLED PLAN OPTION STYLE START */

.planOptionItem.is-disabled .planOptionItemRadioInput label {
    color: #91ADC1 !important;
}

.planOptionItem.is-disabled .planOptionItemRadioInput label:before {
    border-color: #91ADC1 !important;
    opacity: 0;
}

.planOptionItem.is-disabled .insurance-main-select__placeholder {
    color: #91ADC1 !important;
}

.planOptionItem.is-disabled .planOptionItemSelectInput .insurance-main-select__control {
    background-color: transparent !important;
}

.planOptionItem.is-disabled .planOptionItemSelectInput .insurance-main-select__indicator {
    color: #91ADC1 !important;
}

.planOptionItem.is-disabled:hover {
    background-color: transparent !important;
}

.planOptionItem.is-disabled .planOptionItemRadioInput label {
    cursor: not-allowed;
}

/* DISABLED PLAN OPTION STYLE END */


.planLogoSection {
    -ms-flex: 0 0 16%;
    flex: 0 0 16%;
    max-width: 16%;
}

.planTypeOfCoverageSection {
    -ms-flex: 0 0 19%;
    flex: 0 0 19%;
    max-width: 19%;
}

.planPriceSection {
    -ms-flex: 0 0 36%;
    flex: 0 0 36%;
    max-width: 36%;
}

.planButtonsSection {
    -ms-flex: 0 0 29%;
    flex: 0 0 29%;
    max-width: 29%;
}

.plansIsNotFound {
    text-align: center;
    border: 1px solid #1BCD78;
    border-radius: 5px;
    padding: 50px 20px;
    text-align: center;
    width: 100%;
    background-color: #FFFFFF;
}

.plansIsNotFound .icon-search {
    color: #FF5353;
    margin-bottom: 18px;
    display: inline-block;
    font-size: 30px;
}

.plansIsNotFoundTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #32335C;
}

.plansIsNotFoundTitle span {
    font-weight: bold;
    display: block;
}

.updateInsurancePlansButton {
    padding: 0;
    height: 41px;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 15px;
}

.updateInsurancePlansButtonFixedBlock {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    padding: 10px 30px;
    max-width: 400px;
    z-index: 2;
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
}

.updateInsurancePlansButtonFixedBlock button {
    margin-top: 0;
}

.inputBoxResultPage.last-element {
    border: none;
    padding-bottom: 10px;
}

.test_id_block {
    position: absolute;
}

.insurancePlanFirstRow,
.insurancePlanLogoBlock {
    height: 100%;
}

.compare-check-button {
    margin-left: 8px;
    margin-bottom: 0;
    display: inline-block !important;
    width: calc(100% - 83px);
    height: 41px;
    line-height: 41px;
    padding: 0;
    background: transparent;
    /*float: right;*/
}

.compare-check-button .check-button--checkbox {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    padding: 0;
    height: 100%;
    width: 100%;
    display: block;
    border: 1px solid #FFCB12;
    border-radius: 5px;
    cursor: pointer;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.compare-check-button .check-button--checkbox:hover {
    background-color: #FFCB12;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.check-button--checkbox .icon-trash {
    display: none;
}

.compare-button-icon {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 25px;
    height: 25px;
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    color: #FFFFFF;
    font-size: 11px;
}

.compare-button-icon:before {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform:    translate(-50%, -50%);
    -ms-transform:     translate(-50%, -50%);
    -o-transform:      translate(-50%, -50%);
    transform:         translate(-50%, -50%);
}

.check-button--checkbox .icon-compare {
    background: #FFCB12;
}

.check-button--checkbox .icon-trash {
    background-color: #FF5353;
}

.compare-check-button label {
    width: 100%;
    height: 100%;
}

.compare-check-button input:checked ~ .check-button--checkbox {
    color: #FF5353;
    border-color: #FF5353 !important;
    background-color: transparent !important;
}

.compare-check-button input:checked ~ .check-button--checkbox .icon-compare {
    display: none !important;
}

.compare-check-button input:checked ~ .check-button--checkbox .icon-trash {
    display: block !important;
}

.compare-check-button input:checked ~ .check-button--checkbox:hover {
    background-color: #FF5353 !important;
    color: #FFFFFF;
}

.compareFixedModalBlock {
    position: fixed;
    bottom: -100%;
    right: 8%;
    background: #FFFFFF;
    border: 3px solid #FFCB12;
    box-sizing: border-box;
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
    border-radius: 5px;
    padding: 10px 15px 20px 15px;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 1s;

    transition-delay: 0.5s;
}

.compareFixedModalBlock.is-visible {
    bottom: 25px;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 1s;
}

.compareFixedModalTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #32335C;
    border-bottom: 1px solid #E7EAF5;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.compareFixedModalButtons button {
    height: 41px;
    border-radius: 5px;
    width: calc(50% - 5px);
    text-align: center;
    display: inline-block;
}

.compareFixedModalButtons button:hover {
    opacity: 0.8;
}

.comparePlansButton {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #32335C;
    background-color: #FFCB12;
    margin-right: 10px;
}

.uncheckAllPlansButton {
    border: 1px solid #FFCB12;
    background-color: #FFFFFF;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #32335C;
}

.uncheckAllPlansButton span {
    color: #FFCB12;
    margin-left: 4px;
    font-size: 13px;
}

.warning-server-timeout .modal-content{
    padding: 0 45px 50px 45px;
}

.warning-server-timeout .modal-title {
    color: #21CB7A;
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
}

.warning-server-timeout .modal-body {
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: #32335C;
}

@media screen and (max-width: 767px){
    .warning-server-timeout .modal-content{
        padding: 20px 15px;
    }
}
















/* PRICE OPTIONS SELECT STYLE START */

.plan-price-option {
    float: right;
}

.planOptionItemSelectInput .insurance-main-select__value-container {
    height: 30px;
}

.planOptionItemSelectInput .insurance-main-select__placeholder {
    font-weight: bold;
}

.planOptionItemSelectInput .insurance-main-select__control {
    width: 81px;
    height: 32px;
    min-height: 32px;
}

.planOptionItemSelectInput .insurance-main-select__value-container {
    padding-left: 8px !important;
}

.planOptionItemSelectInput .insurance-main-select__option {
    padding-left: 20px !important;
}

.planOptionItemSelectInput .insurance-main-select__indicator {
    padding-left: 6px !important;
    padding-right: 8px !important;
    font-size: 5px;
}

.planOptionItemSelectInput .insurance-main-select__single-value {
    color: #32335C !important;
}


/* PRICE OPTIONS SELECT STYLE END */





/* PRICE OPTIONS RADIO STYLE START */

.planOptionItemRadioInput > div {
    position: absolute;
    top: 50%;
    left: 15px;
    line-height: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform:    translateY(-50%);
    -ms-transform:     translateY(-50%);
    -o-transform:      translateY(-50%);
    transform:         translateY(-50%);
}


.planOptionItemRadioInput label {
    padding-left: 24px;
    padding-right: 0 !important;
    font-size: 13px !important;
    line-height: 13px !important;
    text-align: left !important;
    color: #32335C !important;
}

.planOptionItemRadioInput [type="radio"]:checked + label {
    color: #1BCD78 !important;
}

.planOptionItemRadioInput label:before {
    left: 0;
    top: 50% !important;
    right: initial !important;
    width: 18px !important;
    height: 18px !important;
    -webkit-transform: translateY(-50%);
    -moz-transform:    translateY(-50%);
    -ms-transform:     translateY(-50%);
    -o-transform:      translateY(-50%);
    transform:         translateY(-50%);
}

.planOptionItemRadioInput label:after {
    left: 4px;
    top: calc(50% - 5px) !important;
    right: initial !important;
    width: 10px !important;
    height: 10px !important;
    -webkit-transform: translateY(-50%);
    -moz-transform:    translateY(-50%);
    -ms-transform:     translateY(-50%);
    -o-transform:      translateY(-50%);
    transform:         translateY(-50%);
}

.planOptionItemRadioInput {
    position: relative;
}

.planOptionItemRadioInput:after {
    /*content: '';*/
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
}

/* PRICE OPTIONS RADIO STYLE END */






/* CHECKBOX CUSTOM STYLE START */

.check-button-container {
    position: relative;
    display: block;
}

.check-button {
    display: block;
    position: relative;
    padding-right: 30px;
    padding-left: 15px;
    cursor: pointer;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #32335C;
    background: #FFFFFF;
    border: 1px solid #E0E1E5;
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    line-height: 40px;
}

.check-button-container {
    margin-bottom: 10px;
}

.check-button-container:last-child {
    margin-bottom: 0 !important;
}

.check-button-container input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.check-button__indicator {
    position: absolute;
    top: 50%;
    right: 8px;
    height: 26px;
    width: 26px;
    background: #FFFFFF;
    border: 1px solid #E0E1E5;
    box-sizing: border-box;
    border-radius: 5px;

    -webkit-transform: translateY(-50%);
    -moz-transform:    translateY(-50%);
    -ms-transform:     translateY(-50%);
    -o-transform:      translateY(-50%);
    transform:         translateY(-50%);
}

.check-button-container:hover input ~ .check-button--checkbox .check-button__indicator {
    background-color: #E3F4EF;
    /*border-color: #FFFFFF;*/
}

.check-button-container:hover input ~ .check-button--checkbox .check-button__indicator span {
    color: #FFFFFF;
}

.check-button-container input:checked ~ .check-button--checkbox .check-button__indicator {
    background-color: #1BCD78;
    border-color: #1BCD78;
}

.check-button-container input:checked ~ .check-button--checkbox {
    border-color: #1BCD78;
    background-color: #E3F4EF;
}

.check-button-container input:checked:disabled ~ .check-button--checkbox {
    background-color: #E3F4EF;
    color: #1BCD78;
    border-color: #E3F4EF;
    cursor: not-allowed;
}

.check-button-container input:checked:disabled ~ .check-button--checkbox .check-button__indicator {
    border-color: transparent;
    background-color: transparent;
}

.check-button-container input:checked:disabled ~ .check-button--checkbox .check-button__indicator span {
    color: #1BCD78 !important;
}

.check-button__indicator span {
    font-size: 9px;
    color: #E0E1E5;
    position: absolute;
    left: 50%;
    top: 50%;

    -webkit-transform: translate(-50%, -50%);
    -moz-transform:    translate(-50%, -50%);
    -ms-transform:     translate(-50%, -50%);
    -o-transform:      translate(-50%, -50%);
    transform:         translate(-50%, -50%);
}

.check-button-container input:checked ~ .check-button--checkbox .check-button__indicator span {
    color: #FFFFFF;
}

.check-button-container input:disabled ~ .check-button--checkbox .check-button__indicator:after {
    border-color: #7b7b7b;
}

/* CHECKBOX CUSTOM STYLE END */







/* SELECT INPUT STYLE START */

.travelInfoCard .insurance-main-select__control {
    height: 40px;
}

.insureesRowResultPage .insurance-main-select__value-container,
.travelInfoCard .dependentsContainer .insurance-main-select__value-container {
    padding-left: 10px !important;
}

.travelInfoCard .insurance-main-select__indicator {
    padding-left: 14px !important;
    padding-right: 14px !important;
}

.insureesRowResultPage .insurance-main-select__indicator,
.travelInfoCard .dependentsContainer .insurance-main-select__indicator {
    padding-left: 5px !important;
    padding-right: 10px !important;
}

.travelInfoCard .insurance-main-select__single-value {
    color: #32335C !important;
}

.travelInfoCard .insurance-main-select__menu {
    min-width: 80px;
}

.travelInfoCard .nat-select .insurance-main-select__menu {
    min-width: 180px;
}

.planOptionItemDeductible .insurance-main-select__menu {
    min-width: 100px;
}

/* SELECT INPUT STYLE END */



/* PLANS LOADER STYLE START */

@-webkit-keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}


.load-content-animation {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: #F6F6F6;
    /*background: linear-gradient(to right, #F6F6F6 18%, #F0F0F0 28%, #F6F6F6 43%);*/
    background: linear-gradient(to right, #e9ecef 7%, #F0F0F0 38%, #F6F6F6 39%);
    background-size: 800px 104px;
    position: relative;
}

.load-content-animation.check-button--checkbox {
    border-color: transparent;
}

.insurancePlanRowLoader .planTypeOfCoverageSection li {
    min-width: 135px;
    margin-bottom: 10px;
    height: 20px;
}

.insurancePlanRowLoader .planTypeOfCoverageSection li:last-child {
    margin-bottom: 0;
}

.insurancePlanRowLoader .planGuaranteeLevelName {
    left: -20px;
    width: 100px;
}

.insurancePlanRowLoader .planPriceTitle .load-content-animation {
    width: 80%;
    height: 20px;
}

.insurancePlanRowLoader .planPriceValue .load-content-animation {
    width: 80%;
    height: 20px;
    float: right;
}

.insurancePlanRowLoader .getPlanButton {
    margin-top: 0;
}

.insurancePlanRowLoader .planButtonsSection {
    padding: 23px 15px 23px 0;
}

.insurancePlanLogoLoader {
    display: inline-block;
    background-color: #F7F8FC;
    border-radius: 5px;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 37%;
    left: 54%;

    -webkit-transform: translate(-50%, -50%);
    -moz-transform:    translate(-50%, -50%);
    -ms-transform:     translate(-50%, -50%);
    -o-transform:      translate(-50%, -50%);
    transform:         translate(-50%, -50%);
}

.insurancePlanRowLoader .secondaryButtonStyle {
    border: none !important;
    box-shadow: none !important;
    cursor: initial;
}

.insurancePlanRowLoader .mainButtonStyle {
    cursor: initial;
}

.insurancePlanRowLoader .mainButtonStyle:after {
    display: none;
}


/* PLANS LOADER STYLE END */


.maximizedPlanVersionClose {
    display: none;
    /*max-height: 0 !important;*/
    /*opacity: 0 !important;*/
    /*overflow: hidden !important;*/
}


/* Warning start*/
.insurancePlansSortAndOptionsContainer .col-12{
    padding-left: 0;
    padding-right: 0;
}
.warningBlock {
    margin-top: 20px;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 12px 84px 0px rgba(55, 87, 124, 0.20);
    padding: 13px 32px !important;
}
.warningBlock .row{
    display: flex;
    align-items: center;
}
.warningBlockImage {
    display: flex;
    align-items: center;
    justify-content: center;
}
.warningBlockImageBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.warningBlockImageBox img{
    /*border-radius: 10px;*/
    /*background: #FFF;*/
    /*box-shadow: 0px 0px 30px 0px rgba(55, 87, 124, 0.15);*/
    /*padding: 11px;*/
}
.warningBlockImageBox p {
    color: #32335C;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    padding-top: 4px;
}
.warningBlockText{
    color: #32335C;
    text-align: left;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
.warningBlockText span {
    color: #1BCD78;
    font-weight: 700;
}
/* Warning end*/






























@media screen and (max-width: 1199px) {

    .defaultContainer,
    .defaultHeaderStyle .container {
        max-width: 1000px;
    }

    .resultPageLeftPartCol {
        padding-left: 10px;
    }

    .resultPageRightPartCol {
        padding: 0 10px 0 0;
    }

    .resultPageRightPart {
        padding: 17px 15px 100px 35px;
    }

    .planGuaranteeLevelName {
        left: 0;
    }

    .insurancePlansAmountBlock {
        font-size: 16px;
    }

    .insurancePlansAmountBlock p{
        font-size: 12px;
        line-height: 18px;
    }

    .paymentFrequencyBlock label {
        width: calc(100% - 130px);
        font-size: 14px;
        display: inline-block;
    }

    .paymentFrequencyBlock .payment-frequency-option {
        width: 130px;
    }

    .paymentFrequencyBlock label span {
        font-size: 14px;
    }

    .payment-frequency-option .insurance-main-select__single-value {
        font-size: 15px;
    }

    .paymentFrequencyBlock .insurance-main-select__value-container {
        padding-left: 10px !important;
    }

    .insurance-main-select__option {
        font-size: 15px !important;
    }

    .planOptionItemRadioInput label {
        font-size: 13px !important;
    }

    .planLogoSection {
        -ms-flex: 0 0 14%;
        flex: 0 0 14%;
        max-width: 14%;
    }

    .planTypeOfCoverageSection {
        -ms-flex: 0 0 21%;
        flex: 0 0 21%;
        max-width: 21%;
    }

    .planTypeOfCoverageSection ul li {
        font-size: 13px;
    }

    .planLogoSection img {
        max-width: 75px;
    }

    .planPriceSection {
        padding: 0 10px;
        -ms-flex: 0 0 37%;
        flex: 0 0 37%;
        max-width: 37%;
    }

    .planButtonsSection {
        -ms-flex: 0 0 28%;
        flex: 0 0 28%;
        max-width: 28%;
    }

    .planPriceTitle {
        font-size: 14px;
    }

    .planPriceValue {
        font-size: 17px;
    }

    .mainButtonStyle {
        font-size: 18px;
    }

    .planOptionsBlock {
        margin: 0 -15px 0 -15px;
    }

    .insurancePlanItem {
        padding: 15px;
    }

    .check-button {
        font-size: 14px;
    }

    .filterCardTitle {
        font-size: 16px;
    }

    .inputBoxTitle {
        font-size: 14px;
    }

    .filterCard {
        padding: 13px 15px 24px 15px;
    }

    .travelInfoCard .addDependentsButton {
        line-height: 14px;
        font-size: 11px;
    }

    .travelInfoCard .insurance-main-select__single-value {
        font-size: 14px;
    }

    .travelInfoCard .col-6 {
        padding-right: 4px;
    }

    .travelInfoCard .col-4 {
        padding-right: 0;
        padding-left: 4px;
    }

    .travelInfoCard .deleteDependentButton {
        font-size: 18px;
    }

    .insureesRowResultPage .insurance-main-select__indicator,
    .travelInfoCard .dependentsContainer .insurance-main-select__indicator {
        padding-right: 7px !important;
    }

    .insurance-main-select__value-container {
        padding-left: 15px !important;
    }

    .insureesRowResultPage .insurance-main-select__single-value,
    .travelInfoCard .dependentsContainer .insurance-main-select__single-value {
        font-size: 13px;
    }

    .insureesRowResultPage .insurance-main-select__option,
    .travelInfoCard .dependentsContainer .insurance-main-select__option {
        padding-left: 15px !important;
    }

    .planOptionItem {
        padding: 7.5px 5px;
    }

    .viewPlanButton {
        width: 70px;
    }

    .compare-check-button {
        width: calc(100% - 78px);
    }

    /* PLANS LOADER STYLE START */

    .insurancePlanRowLoader .planGuaranteeLevelName {
        left: -13px;
        width: 90px;
    }

    /* PLANS LOADER STYLE END */

    .insurancePlansSortAndOptionsContainer .col-2 .payment-frequency-option {
        width: 90px;
    }

}


@media screen and (max-width: 992px) {

    .resultPageLeftPartCol {
        display: none;
    }

    .resultPageRightPartCol {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .resultPageRightPartCol {
        padding: 0 30px 0 10px;
        max-width: 800px;
        margin: 0 auto;
    }

    .resultPageContainer {
        background-color: #F7F8FC;
    }

    .planPriceSection {
        padding: 0 7px;
    }

    .resultPageContainer {
        min-height: calc(100vh - 66px);
    }

    .getPlanButton {
        margin-bottom: 15px;
    }

    .mobileFilterButtonBlock {
        align-self: center;
    }

    .mobileFilterButton {
        /* TODO: тимчасове рішення*/
        position: fixed;
        z-index: 5;
        top: 15px;
        right: 25px;

        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #32335C;
        background-color: #FFFFFF;
        border: 1px solid #E0E1E5;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 3px 16px 4px 9px;
        height: 32px;
        float: right;

        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }

    .mobileFilterButton span {
        color: #1BCD78;
        margin-right: 19px;
        font-size: 19px;
        top: 3px;
        position: relative;
    }

    .mobileFilterButton:hover {
        border-color: #1BCD78;
        box-shadow: 0px 0px 26px 0px rgba(33,203,122,0.5);
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }

    .filterCard {
        box-shadow: none;
        background-color: transparent;
    }

    .travelInfoCard {
        margin-bottom: 0px;
    }

    .mobileFilterHeader {
        position: fixed;
        z-index: 5;
        left: 0;
        top: 0;
        width: 100%;
        height: 57px;
        border-bottom: 1px solid #E0E1E5;

    }

    .mobileFilterHeaderTitle {
        background-color: #FFFFFF;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #32335C;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform:    translate(-50%, -50%);
        -ms-transform:     translate(-50%, -50%);
        -o-transform:      translate(-50%, -50%);
        transform:         translate(-50%, -50%);
    }

    .mobileFilterCloseButton {
        position: absolute;
        right: 25px;
        top: 50%;
        font-size: 24px;
        color: #91ADC1;
        line-height: 0;
        background-color: transparent;
        -webkit-transform: translateY(-50%);
        -moz-transform:    translateY(-50%);
        -ms-transform:     translateY(-50%);
        -o-transform:      translateY(-50%);
        transform:         translateY(-50%);
    }


    .compareFixedModalBlock {
        right: initial;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform:    translateX(-50%);
        -ms-transform:     translateX(-50%);
        -o-transform:      translateX(-50%);
        transform:         translateX(-50%);
    }


    /* PLANS LOADER STYLE START */

    .insurancePlanLogoLoader {
        width: 70px;
        height: 70px;
    }

    .insurancePlanRowLoader .planTypeOfCoverageSection li {
        min-width: 125px;
    }

    /* PLANS LOADER STYLE END */

    .paymentCurrencyBlock .payment-frequency-option {
        width: 90px;
    }

}


@media screen and (min-width: 768px) {

    .minimizedPlanVersion {
        display: none !important;
    }

}


@media screen and (max-width: 767px) {

    .planLogoSection,
    .planTypeOfCoverageSection,
    .planPriceSection,
    .planButtonsSection {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 15px;
    }

    .planTypeOfCoverageSection ul {
        position: relative;
        top: initial;
        left: initial;
        -webkit-transform: none;
        transform: none;
    }

    .planLogoSection img {
        position: relative;
        top: initial;
        left: initial;
        -webkit-transform: none;
        transform: none;
        max-height: 80px;
        max-width: 90px;
    }

    .insurancePlanItem {
        padding: 20px 15px;
    }

    .resultPageRightPart {
        padding: 15px 15px 140px 15px;
    }

    .resultPageRightPartCol {
        padding: 0 25px;
    }

    .planPriceTitle {
        font-size: 16px;
    }

    .planPriceValue {
        font-size: 21px;
    }

    .planOptionItemRadioInput label {
        font-size: 14px !important;
    }

    .planOptionsSectionBody {
        padding: 0 15px 6px 15px;
    }

    .planOptionItem {
        padding: 7.5px 7px;
    }

    .planTypeOfCoverageSection {
        padding: 15px;
    }

    .planTypeOfCoverageSection ul {
        column-count: 2;
        grid-column-gap: 0px;
        column-gap: 0px;
        padding-left: 10px;
    }

    .getPlanButton {
        margin-top: 0;
        margin-bottom: 13px;
    }

    .insurancePlanRow {
        margin-bottom: 15px;
    }

    .planGuaranteeLevelName {
        position: relative;
        left: initial;
        top: initial;
    }

    .insurancePlanFirstRow {
        height: 80px;
        margin-bottom: 20px;
    }

    .insurancePlanLogoBlock {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .insurancePlanTagBlock {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 20px;
    }

    .insurancePlansSortAndOptionsContainer {
        padding-top: 0;
        padding-bottom: 12px;
    }

    .insurancePlansSortAndOptionsContainer .col-6 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .insurancePlansAmountBlock {
        text-align: center;
        border-bottom: 1px solid #E7EAF5;
        padding-bottom: 11px;
        margin-bottom: 11px;
        line-height: 21px;
    }

    .paymentFrequencyBlock label {
        width: calc(100% - 110px);
        font-size: 14px;
    }

    .paymentFrequencyBlock .payment-frequency-option {
        width: 110px;
    }

    .payment-frequency-option .insurance-main-select__single-value {
        font-size: 17px;
    }

    .payment-frequency-option .insurance-main-select__indicator {
        padding-right: 9px !important;
        padding-left: 5px !important;
    }

    .paymentFrequencyBlock .insurance-main-select__value-container {
        padding-left: 8px !important;
    }

    .insurancePlansSortAndOptionsContainer .insurance-main-select__option {
        font-size: 14px !important;
        padding-left: 15px !important;
    }

    .viewPlanButton {
        width: 90px;
    }

    .compare-check-button {
        width: calc(100% - 98px);
    }

    .insurancePlansAmountBlock span {
        display: block;
    }

    .plansIsNotFoundTitle {
        font-size: 16px;
    }

    .etreRappeleModalBody .container {
        padding: 0;
    }

    .full-window-modal .etreRappeleModalBody {
        padding: 30px 15px 0 15px !important;
    }

    .etreRappeleModalText {
        font-size: 14px;
        line-height: 25px;
        margin: 0px auto 30px auto;
        padding: 0 15px;
    }

    .filter-modal-window .modal-body {
        padding: 15px 15px 0 15px !important;
    }



    /* PLANS LOADER STYLE START */

    .insurancePlanLogoLoader {
        width: 80px;
        height: 80px;
        left: 50%;
        top: 50%;
    }

    .insurancePlanRowLoader .planOptionsSectionBody {
        min-height: 150px;
    }

    .insurancePlanRowLoader .planButtonsSection {
        padding: 0 15px;
    }

    .insurancePlanRowLoader .planGuaranteeLevelName {
        left: 0;
    }

    .insurancePlanRowLoader .planTypeOfCoverageSection ul {
        grid-column-gap: 15px;
        column-gap: 15px;
    }

    /* PLANS LOADER STYLE END */


    .compareFixedModalBlock {
        width: 100%;
        max-width: 100%;
        z-index: 5;
        bottom: -100%;
    }

    .compareFixedModalBlock.is-visible {
        bottom: 0;
    }


    /* START MINIMIZED PLAN VERSION */
    .maximizedPlanVersion {
        /*display: none !important;*/
        max-height: 0 !important;
        opacity: 0 !important;
        overflow: hidden !important;
    }

    .maximizedPlanVersionClose {
        display: block;
        position: absolute;
        right: 20px;
        top: 10px;
        z-index: 2;
    }

    .insurancePlanItem {
        min-height: 136px;
        position: relative;
    }

    .minimizedPlanVersion {
        position: absolute;
        top: 15px;
        left: 15px;
        width: 100%;
    }


    .miniPlanLogoSection {
        -ms-flex: 0 0 85px;
        flex: 0 0 85px;
        max-width: 85px;
        padding-right: 0;
        text-align: center;
    }

    .miniPlanTypeOfCoverageSection,
    .miniPlanPriceSection {
        max-width: calc((100% - 85px) / 2 );
    }

    .miniPlanPriceSection {
        padding-left: 0;
    }

    .miniPlanTypeOfCoverageSection {
        padding: 0 0 5px 10px;
    }

    .miniPlanLogoSection span {
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }

    .miniPlanLogoSection img {
        max-height: 50px;
        max-width: 60px;
        vertical-align: middle;
        top: -3px;
        position: relative;
    }

    .miniPlanTypeOfCoverageSection ul li {
        font-size: 10px;
        line-height: 15px;
    }

    .miniPlanTypeOfCoverageSection ul li.included-coverage {
        font-weight: bold;
    }

    .miniPlanTypeOfCoverageSection .included-coverage .icon-check-mark,
    .miniPlanTypeOfCoverageSection .not-included-coverage .icon-close{
        margin-right: 6px;
        font-size: 6px;
        width: 8px;
    }

    .miniPlanPriceSection .planGuaranteeLevelName {
        display: flex;
        font-size: 12px;
        height: 18px;
        line-height: 19px;
        right: 15px;
        top: 3px;
        position: absolute;
    }

    .miniPlanPriceSection .planGuaranteeLevelName span {
        margin-right: 8px;
        top: 5px;
        font-size: 9px;
    }

    .miniPlanPriceSection .planPriceValue {
        margin-top: 28px;
        font-size: 20px;
        color: #1BCD78;
    }

    .minimizedPlanVersion .insurancePlanFirstRow {
        margin-bottom: 0;
        height: auto;
    }

    .minimizedPlanVersion .viewPlanButton {
        width: calc(50% - 7.5px);
        height: 31px;
        line-height: 30px;
    }

    .minimizedPlanVersion .viewPlanButton span {
        color: #91ADC1;
        top: -1px;
        font-size: 8px;
        margin-left: 12px;
        position: relative;
    }

    .minimizedPlanVersion .compare-check-button {
        width: calc(50% - 7.5px);
        margin-left: 15px;
        height: 31px;
        line-height: 30px;
    }

    .minimizedPlanVersion .planButtonsRow {
        border-top: 1px solid #E7EAF5;
        padding-top: 10px;
        margin-top: 3px;
    }

    .insurancePlanRow.null .insurancePlanItem {
        padding: 15px 15px 12px 15px;
    }





    .insurancePlanRow.is-maximized .minimizedPlanVersion {
        /*display: none !important;*/
        visibility: hidden;
        opacity: 0;

        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }

    .insurancePlanRow.is-maximized .maximizedPlanVersion {
        opacity: 1 !important;
        max-height: 500px !important;

        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.5s;

        transition-delay: 0.3s;
    }


    .mobileFilterButton {
        /* TODO: тимчасове рішення*/
        top: 11px;
        right: 25px;
    }

    .paymentCurrencyBlock {
        float: right;
    }


    .insurancePlansSortAndOptionsContainer .col-4{
        flex: 0 0 66.66666%;
        max-width: 66.66666%;
    }

    .insurancePlansSortAndOptionsContainer .col-2{
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .insurancePlansSortAndOptionsContainer .col-2 .payment-frequency-option {
        width: 80px;
    }

    .paymentCurrencyBlock .payment-frequency-option {
        width: 90px;
    }

    /* Warning start */
    .warningBlock{
        padding: 5px 15px 9px 15px!important;
    }
    .warningBlockImage{
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: flex-start;
    }
    .warningBlockImageBox {
        flex-direction: row;
    }
    .warningBlockImageBox img{
        width: 25px;
    }
    .warningBlockImageBox p {
        margin-top: 0;
        margin-left: 4px;
    }
    .warningBlockText{
        flex: 0 0 100%;
        max-width: 100%;
        font-size: 12px;
        line-height: 130%;
        margin-top: 2px;
    }


}


@media screen and (max-width: 370px) {

    .resultPageRightPartCol {
        padding: 0 15px;
    }




}



@media screen and (max-width: 350px) {


    .paymentFrequencyBlock label {
        font-size: 13px;
        padding-right: 10px;
    }

    .paymentFrequencyBlock label span {
        display: none;
    }

    .planOptionItemRadioInput label {
        font-size: 13px !important;
    }

    .planPriceTitle {
        font-size: 15px;
    }

    .planPriceValue {
        font-size: 19px;
    }

    .included-coverage .icon-check-mark {
        margin-right: 7px;
        font-size: 8px;
        width: 10px;
    }

    .not-included-coverage .icon-close {
        margin-right: 5px;
        font-size: 8px;
        width: 10px;
    }

    .planTypeOfCoverageSection ul li {
        font-size: 13px !important;
    }

    .insurancePlanTagBlock {
        padding-left: 0;
    }

    .full-window-modal .etreRappeleModalBody {
        padding: 30px 0px 0 0px !important;
    }

    .mobileFilterButton span {
        margin-right: 10px;
    }

    .miniPlanTypeOfCoverageSection {
        padding: 0 0 5px 5px;
    }

    .miniPlanLogoSection {
        flex: 0 0 70px;
        max-width: 70px;
    }

    .miniPlanTypeOfCoverageSection, .miniPlanPriceSection {
        max-width: calc((100% - 70px) / 2 );
    }

    .miniPlanPriceSection .planPriceValue {
        font-size: 17px;
    }

    .miniPlanPriceSection .planGuaranteeLevelName {
        font-size: 11px;
    }

    .miniPlanLogoSection img {
        max-height: 40px;
        max-width: 50px;
    }

}
