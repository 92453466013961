.viewPlanRow {
    max-width: 830px;
    margin: 0 auto;
    z-index: 1;
    position: relative;
}

.viewPlanLocalDataRow .planLogoSection {
    -ms-flex: 0 0 19%;
    flex: 0 0 19%;
    max-width: 19%;
}

.viewPlanLocalDataRow .planTypeOfCoverageSection {
    -ms-flex: 0 0 31%;
    flex: 0 0 31%;
    max-width: 31%;
}

.viewPlanLocalDataRow .planPriceSection {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.viewPlanLocalDataRow .paymentFrequencyBlock {
    float: none;
}

.viewPlanLocalDataRow .planLogoSection img {
    left: 15px;
    -webkit-transform: translateY(-50%);
    -moz-transform:    translateY(-50%);
    -ms-transform:     translateY(-50%);
    -o-transform:      translateY(-50%);
    transform:         translateY(-50%);
}

.viewPlanLocalDataRow .planTypeOfCoverageSection ul {
    top: 50%;
    left: 50%;
    display: block;
    min-width: 155px;

    -webkit-transform: translate(-50%, -50%);
    -moz-transform:    translate(-50%, -50%);
    -ms-transform:     translate(-50%, -50%);
    -o-transform:      translate(-50%, -50%);
    transform:         translate(-50%, -50%);
}

.viewPlanLocalDataRow .paymentFrequencyBlock label {
    font-size: 14px;
    line-height: 16px;
    position: relative;
    top: 6px;
    width: calc(100% - 136px);
}

.viewPlanLocalDataRow .planPriceBlock {
    padding: 13px 15px;
    background-color: #F7F8FC;
    margin: 10px 0 0 0;
    border: none;
}

.viewPlanRow .planPriceTitle,
.viewPlanRow .planPriceValue {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
}

.viewPlanServerDataRow {
    width: 770px;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    /*padding: 20px 0 0px 0;*/
}

.viewPlanLocalDataRow .insurancePlanItem {
    padding: 21px 68px 25px 68px;
}

.viewPlanLocalDataRow .getPlanButton {
    margin: 10px 0 0 0;
}

.viewPlanDataSwitcherBlock {
    padding: 15px 0;
    background-color: #F7F8FC;
}

.viewPlanDataSwitcherTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #32335C;
    text-align: center;
    margin-bottom: 20px;
}

.viewPlanDataSwitcherBlock .radioBlockRow .col-6:first-child {
    text-align: right;
    padding-right: 10px;
}

.viewPlanDataSwitcherBlock .radioBlockRow .col-6:last-child {
    text-align: left;
    padding-left: 10px;
}

.viewPlanRepLiabBlock {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-align: center;
    padding: 35px 50px;
    color: #32335C;
}

.dataTypeRadioLabel {
    padding: 8px 15px 8px 50px !important;
    border-radius: 5px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.dataTypeRadioLabel:hover {
    background-color: #E4F5ED;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

[type="radio"]:checked + .dataTypeRadioLabel {
    background-color: #FFFFFF;
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
}

.dataTypeRadioLabel:before {
    right: initial;
    left: 15px;
    top: 50% !important;
    -webkit-transform: translateY(-50%);
    -moz-transform:    translateY(-50%);
    -ms-transform:     translateY(-50%);
    -o-transform:      translateY(-50%);
    transform:         translateY(-50%);
}

.dataTypeRadioLabel:after {
    right: initial;
    left: 20.5px;
    top: 50% !important;
    -webkit-transform: translateY(-50%) scale(0) !important;
    -moz-transform:    translateY(-50%) scale(0) !important;
    -ms-transform:     translateY(-50%) scale(0) !important;
    -o-transform:      translateY(-50%) scale(0) !important;
    transform:         translateY(-50%) scale(0) !important;
}

[type="radio"]:checked + .dataTypeRadioLabel:after {
    -webkit-transform: translateY(-50%) scale(1) !important;
    -moz-transform:    translateY(-50%) scale(1) !important;
    -ms-transform:     translateY(-50%) scale(1) !important;
    -o-transform:      translateY(-50%) scale(1) !important;
    transform:         translateY(-50%) scale(1) !important;
}

.viewPlanLocalDataRow .planGuaranteeLevelName {
    left: -80px;
}

.viewPlanLocalDataRow .insurancePlanLogoBlock {
    width: 100%;
    min-height: 110px;
}













/* VIEW PLAN PAYMENT FREQUENCY STYLE START */

.viewPlanLocalDataRow .payment-frequency-option {
    width: 136px !important;
}

.viewPlanLocalDataRow .payment-frequency-option .insurance-main-select__single-value {
    font-size: 15px;
    font-weight: bold;
}

.viewPlanLocalDataRow .insurance-main-select__value-container {
    padding-left: 11px !important;
}

.viewPlanLocalDataRow .insurance-main-select__indicator {
    padding-right: 9px !important;
    padding-left: 9px !important;
}

/* VIEW PLAN PAYMENT FREQUENCY STYLE END */


/* VIEW PLAN ACCORDION STYLE START  */

.viewPlanDataGroup {
    width: 100%;
}

.viewPlanDataGroupHead {
    position: relative;
    padding: 5px 15px;
}

.viewPlanDataGroupHead .accordion__button {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #32335C;
    padding: 11px 23px;
    background-color: transparent;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.viewPlanDataGroupHead .accordion__button:after,
.comparePlansDataGroupItem .accordion__button:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: calc(100% - 76px);
    height: 1px;
    background-color: #E0E1E5;

    webkit-transform: translateX(-50%);
    -moz-transform:    translateX(-50%);
    -ms-transform:     translateX(-50%);
    -o-transform:      translateX(-50%);
    transform:         translateX(-50%);
}

.viewPlanDataGroupHead .accordion__button:hover,
.comparePlansDataGroupItem .accordion__button:hover {
    background-color: #e9ecef;
    border-radius: 5px;
}

.viewPlanDataGroupItem .accordion-indicator,
.comparePlansDataGroupItem .accordion-indicator{
    position: absolute;
    right: 38px;
    top: 50%;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: transparent;

    -webkit-transform: translateY(-50%);
    -moz-transform:    translateY(-50%);
    -ms-transform:     translateY(-50%);
    -o-transform:      translateY(-50%);
    transform:         translateY(-50%);

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.viewPlanDataGroupItem .accordion-indicator .accordion-indicator-icon,
.comparePlansDataGroupItem .accordion-indicator .accordion-indicator-icon {
    margin-left: 17px;
    width: 24px;
    height: 2px;
    display: inline-block;
    position: relative;
    top: -5px;
    background-color: #03D773;
}

.viewPlanDataGroupItem .accordion-indicator .accordion-indicator-icon:after,
.comparePlansDataGroupItem .accordion-indicator .accordion-indicator-icon:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 24px;
    height: 2px;
    display: block;
    background-color: #03D773;


    -webkit-transform: translate(-50%, -50%) rotate(90deg);
    -moz-transform:    translate(-50%, -50%) rotate(90deg);
    -ms-transform:     translate(-50%, -50%) rotate(90deg);
    -o-transform:      translate(-50%, -50%) rotate(90deg);
    transform:         translate(-50%, -50%) rotate(90deg);

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.viewPlanDataGroupItem .accordion__button[aria-expanded='true'] .accordion-indicator,
.comparePlansDataGroupItem .accordion__button[aria-expanded='true'] .accordion-indicator {
    color: #03D773;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.viewPlanDataGroupItem .accordion__button[aria-expanded='true'] .accordion-indicator .accordion-indicator-icon:after,
.comparePlansDataGroupItem .accordion__button[aria-expanded='true'] .accordion-indicator .accordion-indicator-icon:after{
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -moz-transform:    translate(-50%, -50%) rotate(0deg);
    -ms-transform:     translate(-50%, -50%) rotate(0deg);
    -o-transform:      translate(-50%, -50%) rotate(0deg);
    transform:         translate(-50%, -50%) rotate(0deg);

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.viewPlanDataGroupItem .accordion__button:before,
.comparePlansDataGroupItem .accordion__button:before{
    display: none !important;
}

.viewPlanDataGroupItem .accordion__button[aria-expanded='true'],
.comparePlansDataGroupItem .accordion__button[aria-expanded='true']{
    color: #03D773 !important;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.viewPlanDataGroupItem .accordion__panel,
.comparePlansDataGroupItem .accordion__panel {
    padding: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #F7F8FC;
}

.viewPlanDataGroupItem td {
    /*border: none;*/
    /*vertical-align: middle;*/

    border: 1px solid rgba(0, 0, 0, 0.05);
    vertical-align: middle !important;
    width: 190px;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #000000;
}

.viewPlanDataGroupItem td:last-child {
    /*text-align: left;*/
    /*font-family: Poppins;*/
    /*font-style: normal;*/
    /*font-weight: bold;*/
    /*font-size: 14px;*/
    /*line-height: 24px;*/
    /*color: #000000;*/
    /*width: 60%;*/
    /*padding-left: 45px;*/
    /*padding-right: 80px;*/

    text-align: left;
    padding-left: 30px;
    padding-right: 30px;
    border-right: none;
}

.viewPlanDataGroupItem td:first-child {
    /*text-align: right;*/
    /*font-family: Poppins;*/
    /*font-style: normal;*/
    /*font-weight: normal;*/
    /*font-size: 16px;*/
    /*line-height: 24px;*/
    /*color: #000000;*/
    width: 40% !important;
    /*max-width: 40% !important;*/

    font-weight: bold;
    background-color: #F7F8FC;
    /*width: 200px !important;*/
    /*max-width: 200px !important;*/
    text-align: right !important;
    padding-left: 15px;
    border-left: none;
    padding-right: 15px;
}

.coverageSumRow {
    background: #E4F5ED;
    border-radius: 10px;
    padding: 12px 23px;
    margin: 20px 0 0 0;
}
.coverageSumRow + .coverageSumRow {
    margin: 10px 0 0 0;
}

.coverageSumTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #32335C;
    padding: 0;
    display: flex;
    align-items: center;
}

.coverageSumValue {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #1BCD78;
    padding: 0;
}

.non-value {
    color: #FF5353 !important;
}

.non-value span {
    position: relative;
    top: 3px;
}

.viewPlanDataGroupItem .table {
    margin-bottom: 0;
}

.viewPlanDataGroupItem .table-hover tbody tr:hover {
    background-color: #e9ecef;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.viewPlanDataGroupItem.not-available {
    cursor: not-allowed !important;
}

.viewPlanDataGroupItem.not-available .accordion-indicator {
    display: none !important;
}

.viewPlanDataGroupItem.not-available .viewPlanDataGroupHead .accordion__button {
    color: #E0E1E5 !important;
    cursor: not-allowed !important;
}

/* VIEW PLAN ACCORDION STYLE END  */















@media screen and (max-width: 992px) {

    .viewPlanServerDataRow {
        width: 90%;
    }

    .viewPlanLocalDataRow .insurancePlanItem {
        padding: 21px 40px 25px 40px;
    }

    .viewPlanLocalDataRow .planTypeOfCoverageSection {
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        max-width: 29%;
    }

    .viewPlanLocalDataRow .planPriceSection {
        -ms-flex: 0 0 52%;
        flex: 0 0 52%;
        max-width: 52%;
    }

    .viewPlanDataGroupItem td:first-child {
        line-height: 20px;
    }

    .viewPlanDataGroupItem td:last-child {
        line-height: 20px;
        padding-right: 45px;
    }

    .viewPlanLocalDataRow .summaryCardGuaranteeLevel {
        margin: 0 auto;
        display: block;
    }

    .viewPlanLocalDataRow .planGuaranteeLevelName {
        left: -40px;
    }

}


@media screen and (max-width: 767px) {

    .viewPlanContainer {
        padding: 0;
    }

    .viewPlanLocalDataRow .planLogoSection,
    .viewPlanLocalDataRow .planTypeOfCoverageSection,
    .viewPlanLocalDataRow .planPriceSection {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 15px;
    }

    .viewPlanServerDataRow {
        width: 100%;
        padding-top: 20px;
        margin-top: -20px;
    }

    .accordion-indicator-title {
        display: none;
    }

    .viewPlanLocalDataRow .planLogoSection img {
        position: relative;
        top: initial;
        left: initial;
        transform: none;
        max-height: 100px;
        max-width: 166px;
    }

    .viewPlanLocalDataRow  .planGuaranteeLevelName {
        position: relative;
        left: initial;
        top: initial;
    }

    .viewPlanLocalDataRow .planTypeOfCoverageSection ul {
        position: relative;
        left: initial;
        top: initial;
        transform: none;
    }

    .viewPlanLocalDataRow .insurancePlanItem {
        padding: 15px;
    }

    .viewPlanLocalDataRow .insurancePlanLogoBlock {
        margin: 10px 0;
    }

    .planTypeOfCoverageSection ul {
        padding: 0;
    }

    .planTypeOfCoverageSection ul li {
        font-size: 14px;
    }

    .viewPlanLocalDataRow .planPriceSection .col-12 {
        margin: 15px 0 0 0;
    }

    .viewPlanLocalDataRow .paymentFrequencyBlock label {
        top: -5px;
        line-height: 20px;
    }

    .viewPlanRow .planPriceTitle {
        font-size: 16px;
    }

    .viewPlanRow .planPriceValue {
        font-size: 18px;
    }

    .viewPlanDataGroupHead .accordion__button {
        font-size: 18px;
        line-height: 27px;
    }

    .viewPlanDataGroupHead .accordion__button:after {
        width: calc(100% - 30px);
    }

    .coverageSumTitle {
        font-size: 15px;
    }

    .coverageSumRow {
        padding: 12px;
    }

    .viewPlanDataGroupItem td:first-child {
        /*width: 30%;*/
        /*max-width: 30%;*/
        padding: 10px 15px 10px 5px;
        line-height: 17px;
        font-size: 13px;
    }

    .viewPlanDataGroupItem td:last-child {
        width: 70%;
        max-width: 70%;
        padding: 10px 15px;
        line-height: 16px;
        font-size: 12px;
    }

    .viewPlanRepLiabBlock {
        padding: 25px 15px;
        font-size: 13px;
        line-height: 17px;
    }

    .viewPlanDataSwitcherBlock .col-6:first-child {
        margin-bottom: 5px;
    }

    .viewPlanDataSwitcherBlock .col-6 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center !important;
    }

    .viewPlanDataSwitcherBlock {
        padding: 20px 0;
    }

}


@media screen and (max-width: 370px) {


}


@media screen and (max-width: 350px) {

    .coverageSumTitle {
        font-size: 13px;
    }

    .viewPlanDataGroupHead .accordion__button {
        padding: 11px;
    }

    .viewPlanDataGroupItem .accordion-indicator {
        right: 25px;
    }

    .viewPlanRow .planPriceTitle {
        font-size: 14px;
    }

}






